import axios from 'axios'
import React, { Component, Fragment } from 'react'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import Helmet from 'react-helmet'
import { StickyContainer } from 'react-sticky'
import VisibilitySensor from 'react-visibility-sensor'
import { enableChat } from '../../../utility/chat'
import Card from '../components/Card'
import FiltersHeader from './FiltersHeader'

const searchEventArguments = {
  dataLayer: { event: 'searchEvent' },
  dataLayerName: 'CYMDataLayer',
}

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nextPage: null,
      profiles: [],
      moreProfiles: [],
      hasLoadedAllSearchMatches: false,
      loading: props.nameOnly ? false : true,
      displayOpen: false,
      allSkills: [],
      allCerts: [],
    }
    this.loadProfiles()
    if (process.env.REACT_APP_GA_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID, { debug: true })
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: document.title })
    }
    enableChat()
  }

  componentDidMount = () => {
    this.props.setFooterVisibility(false)
  }

  componentWillUnmount = () => {
    this.props.setFooterVisibility(true)
  }

  handleSearch = e => {
    e.preventDefault()
    this.reset(() => this.loadProfiles())
  }

  queryString = params =>
    Object.keys(params)
      .filter(key => !!params[key] && !(Array.isArray(params[key]) && params[key].length === 0))
      .map(key => key + '=' + params[key])
      .join('&')

  getFilters = () => {
    const { showFooter, locationDisplayName, locationAttempted, allSkills, allCerts, ...filters } = this.props.filters
    return filters
  }

  getSearchUrl = () => {
    const filters = this.getFilters()
    if (this.props.nameOnly) {
      if (filters && filters.name && !filters.name.length) return
    }

    const queryString = this.queryString(filters)
    return `${process.env.REACT_APP_API_LOCATION}/api/profiles?${queryString}`
  }

  loadProfiles(url = this.getSearchUrl(), filters = false) {
    url = !filters ? url : url + `&${this.queryString(filters)}`
    if (this.props.nameOnly) {
      if (!filters) {
        filters = this.getFilters()
      }
      if (filters && !filters.name) {
        console.log(filters.name.length)
        return
      }
    }

    this.setState({ loading: true })
    axios.get(url).then(response => {
      TagManager.dataLayer(searchEventArguments)
      this.handleResults(response.data)
      this.setState({ loading: false })
    })
  }

  handleResults({ next_page_url, data, first_page_url }) {
    const newState = {
      profiles: this.state.profiles,
      moreProfiles: this.state.moreProfiles,
      nextPage: next_page_url,
    }

    if (!this.state.hasLoadedAllSearchMatches) {
      newState.profiles = newState.profiles.concat(data)
      if (!next_page_url) {
        newState.hasLoadedAllSearchMatches = true
        newState.nextPage = first_page_url + '&inverse=true'
      }
    } else {
      newState.moreProfiles = newState.moreProfiles.concat(data)
    }

    this.setState(newState)
  }

  handleFiltersChanged = async filtersObj => {
    for (const key of Object.keys(filtersObj)) {
      await this.props.onFilterChange(key, filtersObj[key])
    }
  }

  handleAutoload = isVisible => isVisible && this.loadProfiles(this.state.nextPage, this.getFilters())

  reset(callback) {
    this.setState(
      {
        hasLoadedAllSearchMatches: false,
        profiles: [],
        moreProfiles: [],
      },
      callback
    )
  }

  render() {
    const { filters, onFilterChange } = this.props

    return (
      <div className="AppWrapper" id="Search">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Marketing Made SImple | Provider Search Results</title>
        </Helmet>
        <StickyContainer>
          <FiltersHeader filters={filters} onFilterChange={onFilterChange} handleSearch={this.handleSearch} />
          <div className="pw" id="profiles">
            {this.renderProfiles()}
            {this.renderMoreProfiles()}
            {this.renderAutoLoader()}
          </div>
        </StickyContainer>
      </div>
    )
  }

  renderLoadingIndicator = () => (
    <div className="loading pw">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )

  renderAutoLoader() {
    if (this.state.loading) return this.renderLoadingIndicator()
    if (!this.state.nextPage) return null
    return (
      <VisibilitySensor onChange={this.handleAutoload}>
        <div>&nbsp;</div>
      </VisibilitySensor>
    )
  }

  renderProfiles() {
    const hasResults = this.state.profiles.length > 0
    const doneLoading = this.state.hasLoadedAllSearchMatches
    const zero = doneLoading && !hasResults

    if (zero)
      return (
        <div className="zero-count">
          <div className="zero-count__title">
            <span className="pop">0 experts</span> match your criteria
          </div>
          <div className="zero-count__copy">Use the toolbar above to refine your search</div>
        </div>
      )

    return (
      <div className="Cards">
        {this.state.profiles.map(profile => (
          <Card profile={profile} key={profile.id} />
        ))}
      </div>
    )
  }

  renderMoreProfiles() {
    if (this.state.moreProfiles.length === 0) return null
    return (
      <Fragment>
        <div className="Search__no-more">{`There are no more experts matching your search`}</div>
        <div className="Search__separator">
          <span>Other Recommended Experts</span>
        </div>
        <div className="Cards Cards--more">
          {this.state.moreProfiles.map(function (profile) {
            return <Card profile={profile} key={profile.id} />
          })}
        </div>
      </Fragment>
    )
  }
}

export default Search
