import axios from 'axios'
import { AnimatePresence, motion } from 'framer-motion'
import React, { Component, useState } from 'react'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { enableChat } from '../../../utility/chat'

function GoldRingRight() {
  return (
    <figure className="right-0 absolute z-10" style={{ top: '-10px' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="257" height="501" viewBox="0 0 257 501" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M250.571 0.230225C112.5 0.230225 0.571289 112.159 0.571289 250.23C0.571289 388.301 112.5 500.23 250.571 500.23C388.642 500.23 500.571 388.301 500.571 250.23C500.571 112.159 388.642 0.230225 250.571 0.230225ZM250.571 83.5635C158.524 83.5635 83.9046 158.183 83.9046 250.23C83.9046 342.278 158.524 416.897 250.571 416.897C342.619 416.897 417.238 342.278 417.238 250.23C417.238 158.183 342.619 83.5635 250.571 83.5635Z"
          fill="url(#paint0_linear_44_770)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_44_770"
            x1="79.6126"
            y1="433.442"
            x2="394.605"
            y2="127.554"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E7C03A" />
            <stop offset="0.205" stop-color="#FCE39F" />
            <stop offset="0.4" stop-color="#E7C03A" />
            <stop offset="0.543498" stop-color="#D9B12C" />
            <stop offset="1" stop-color="#AD8200" />
          </linearGradient>
        </defs>
      </svg>
    </figure>
  )
}

function GoldRingLeft() {
  return (
    <figure className="absolute left-0 z-10" style={{ bottom: '0px' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="374" height="751" viewBox="0 0 374 751" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M-1.81641 750.23C205.29 750.23 373.184 582.337 373.184 375.23C373.184 168.123 205.29 0.230225 -1.81641 0.230225C-208.923 0.230225 -376.816 168.123 -376.816 375.23C-376.816 582.337 -208.923 750.23 -1.81641 750.23ZM-1.81641 625.23C136.255 625.23 248.184 513.301 248.184 375.23C248.184 237.159 136.255 125.23 -1.81641 125.23C-139.888 125.23 -251.816 237.159 -251.816 375.23C-251.816 513.301 -139.888 625.23 -1.81641 625.23Z"
          fill="url(#paint0_linear_44_774)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_44_774"
            x1="254.622"
            y1="100.412"
            x2="-217.867"
            y2="559.245"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E7C03A" />
            <stop offset="0.205" stop-color="#FCE39F" />
            <stop offset="0.4" stop-color="#E7C03A" />
            <stop offset="0.543498" stop-color="#D9B12C" />
            <stop offset="1" stop-color="#AD8200" />
          </linearGradient>
        </defs>
      </svg>
    </figure>
  )
}

function GlassCard({ Svg, title, copy, onClick }) {
  return (
    <div className="glass-card flex flex-col justify-between">
      <div className="flex flex-col gap-4">
        <div className="glass-card-icon-container">
          <figure>
            <Svg />
          </figure>
        </div>
        <div>
          <p className="glass-card-heading">{title}</p>
          <p className="glass-card-copy">{copy}</p>
        </div>
      </div>
      <button onClick={onClick} className="glass-card-link">
        See Coaches{' '}
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
          <path
            d="M9.57227 17C13.9905 17 17.5723 13.4183 17.5723 9C17.5723 4.58172 13.9905 1 9.57227 1C5.15399 1 1.57227 4.58172 1.57227 9C1.57227 13.4183 5.15399 17 9.57227 17Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M6.23877 9H12.9054" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M10.2388 6.33337L12.9054 9.00004L10.2388 11.6667"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
  )
}

function HiringStep({ Svg, title, copy }) {
  return (
    <div className="hiring-step-container">
      <figure className="hiring-gold-border rounded-full h-40 w-40 flex items-center justify-center">{<Svg />}</figure>
      <div style={{ maxWidth: '300px' }}>
        <p className="hiring-step-title">{title}</p>
        <p className="hiring-step-copy">{copy}</p>
      </div>
    </div>
  )
}

// Carousel.jsx

function TestimonialCarousel({ items = [], mobileOnly }) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [direction, setDirection] = useState(0)

  const slideVariants = {
    enter: direction => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: direction => ({
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }),
  }

  const swipeConfidenceThreshold = 10000
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity
  }

  const paginate = newDirection => {
    setDirection(newDirection)
    setCurrentIndex(prevIndex => (prevIndex + newDirection + items.length) % items.length)
  }

  return (
    <div className="carousel-container lg:mt-6 mt-16">
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={currentIndex}
          className="carousel-slide"
          custom={direction}
          variants={slideVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x)
            if (swipe < -swipeConfidenceThreshold) {
              paginate(1)
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1)
            }
          }}
          bgColor={items[currentIndex].color}
        >
          <blockquote className="carousel-testimonial">"{items[currentIndex].testimonial}"</blockquote>
          <small className="carousel-testimonial-author">- {items[currentIndex].author}</small>
        </motion.div>
      </AnimatePresence>

      <div className=" carousel-dots-container">
        {items.map((_, index) => (
          <button
            key={index}
            className="carousel-dot"
            onClick={() => {
              const direction = index - currentIndex
              setDirection(direction)
              setCurrentIndex(index)
            }}
            style={{
              backgroundColor: index === currentIndex ? 'black' : '#00000040',
            }}
          />
        ))}
      </div>
    </div>
  )
}

const homePageLoadEventArguments = {
  dataLayer: {
    event: 'homePageLoad',
  },
  dataLayerName: 'CYMDataLayer',
}
const tagManagerArguments = {
  gtmId: process.env.REACT_APP_GTM_ID,
  dataLayerName: 'CYMDataLayer',
}

class Home extends Component {
  constructor(props) {
    super(props)

    if (process.env.REACT_APP_GA_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID)
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: document.title })
    }

    TagManager.initialize(tagManagerArguments)
    TagManager.dataLayer(homePageLoadEventArguments)
    enableChat()

    this.state = { filters: {} }
  }

  onSkillClick = selectedSkill => {
    const skillId = this.props.filters.allSkills.filter(skill => selectedSkill === skill.name)
    if (skillId.length > 0) {
      this.props.onFilterChange('skills', [skillId[0].id.toString()])
    }
    this.handleSearch()
  }

  onBlockClick = filter => {
    const filterInState =
      filter.type in this.state.filters &&
      this.state.filters[filter.type].find(f => f.name.toLowerCase() === filter.name.toLowerCase())
    if (filterInState) {
      this.props.router.push(
        {
          pathname: '/search',
        },
        {
          preSelectedFilters: {
            ...filterInState,
            type: filter.type,
            name: filter.name,
          },
        }
      )
    } else {
      this.props.router.push(
        {
          pathname: '/search',
        },
        {
          preSelectedFilters: { type: filter.type, name: filter.name },
        }
      )
    }
  }

  handleSearch = () => this.props.router.push('/search')

  componentDidMount() {
    this.props.onFilterChange('skills', [])
    this.props.onFilterChange('budget', '')
    this.props.onFilterChange('query', '')
    this.props.onFilterChange('lat', '')
    this.props.onFilterChange('lng', '')
    this.props.onFilterChange('locationDisplayName', '')

    axios
      .get(`${process.env.REACT_APP_API_LOCATION}/api/coach_filters_new?site=mms`)
      .then(response => {
        this.setState({
          filters: response.data,
        })
      })
      .catch(e => console.log(e))
  }
  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Marketing Made Simple | Home</title>
        </Helmet>

        <section id="home-video" className="home-w-full home-section bg-black flex flex-col items-center relative">
          <div className="absolute h-full w-full">
            <div className="absolute h-full w-full bg-gradient-to-b from-black  to-transparent"></div>
            <video className="object-cover object-center h-full w-full" crossOrigin="anonymous" autoPlay loop muted>
              <source
                src="https://player.vimeo.com/progressive_redirect/playback/1050925118/rendition/1080p/file.mp4?loc=external&signature=1e97ebef9fab626bb585e90317932a4b2c348d65436150c4b318471b28fce17f"
                type="video/mp4"
              />
            </video>
          </div>
          <h1 className="relative z-20 pt-56 px-2">
            <span className="gold-text-gradient">Hire a Coach</span> <br />{' '}
            <span
              style={{
                color: 'white',
              }}
            >
              to Create Your Marketing
            </span>
          </h1>
          <h3 className="mt-6 relative z-20">
            <span style={{ color: 'white' }}>
              StoryBrand Certified Agencies and Coaches know how to grow your business
            </span>
          </h3>
          <Link
            to="/search"
            className="mt-8 relative z-20 text-white hiring-gold-border-dark hover-light p-6 rounded-full text-base  font-bold no-underline"
          >
            Search for a Coach
          </Link>
        </section>

        <section className="home-section relative w-full">
          <figure
            className="absolute z-10 w-full"
            style={{
              top: '-200px',
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 1224" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M718.111 87.9877C1039.19 94.2388 1501.19 -137.234 1575.02 317.897C1647.64 765.602 1147.46 807.451 870.848 1029.57C740.404 1134.32 617.554 1287.67 482.909 1194.88C171.256 980.103 -314.265 762.795 -212.335 283.099C-109.918 -198.888 371.714 81.2436 718.111 87.9877Z"
                fill="#F5F5F5"
              />
            </svg>
          </figure>
          <h2 className="z-20 relative">How can we help you grow your business?</h2>
          <GoldRingLeft />
          <GoldRingRight />
          <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 relative z-10 lg:p-20 p-4">
            <GlassCard
              onClick={() =>
                this.onBlockClick({
                  type: 'skills',
                  name: 'Copywriting',
                })
              }
              Svg={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                  <path
                    d="M21.5723 24.8572C23.5444 24.8572 25.1431 23.2582 25.1431 21.2858C25.1431 19.3133 23.5444 17.7144 21.5723 17.7144C19.6002 17.7144 18.0015 19.3133 18.0015 21.2858C18.0015 23.2582 19.6002 24.8572 21.5723 24.8572Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.5732 24.8572V42"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.84521 10.5713L1.76061 20.4695C1.61274 20.7044 1.55001 20.9668 1.57928 21.2279C1.60854 21.489 1.72868 21.7388 1.92653 21.9498L21.5734 41.9998L41.218 21.9498C41.4159 21.7388 41.536 21.489 41.5653 21.2279C41.5945 20.9668 41.5318 20.7044 41.3839 20.4695L34.3016 10.5713H8.84521Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M34.3016 10.5714V3.42857C34.3016 3.04969 34.11 2.68633 33.769 2.41842C33.428 2.15051 32.9655 2 32.4833 2H10.6635C10.1813 2 9.71879 2.15051 9.37779 2.41842C9.03679 2.68633 8.84521 3.04969 8.84521 3.42857V10.5714"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              title="Copywriting"
              copy="Whether it's a sales letter, a brochure or a lead generator, our copywriters get results."
            />
            <GlassCard
              onClick={() =>
                this.onBlockClick({
                  type: 'skills',
                  name: 'Sales Funnel Creation',
                })
              }
              Svg={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                  <path
                    d="M2.00549 5.04117C1.7886 4.78078 1.64561 4.45734 1.59386 4.11007C1.54211 3.76279 1.58384 3.4066 1.71396 3.08468C1.84409 2.76276 2.05704 2.48893 2.32698 2.2964C2.59693 2.10388 2.91228 2.00091 3.2348 2H39.9056C40.2284 2.00003 40.5444 2.10236 40.8149 2.29455C41.0855 2.48674 41.2991 2.76051 41.4297 3.08261C41.5604 3.4047 41.6024 3.76126 41.5508 4.10895C41.4992 4.45663 41.3562 4.78049 41.139 5.04117L26.5707 22.0017V35.3323C26.5709 35.6318 26.5033 35.9266 26.3739 36.1907C26.2445 36.4548 26.0573 36.6799 25.829 36.8461L19.1616 41.6942C18.9108 41.8768 18.6193 41.9817 18.3182 41.9978C18.0171 42.0139 17.7176 41.9407 17.4516 41.7858C17.1856 41.6309 16.9631 41.4003 16.8078 41.1184C16.6525 40.8365 16.5702 40.5139 16.5696 40.185V22.0017L2.00549 5.04117Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              title="Sales Funnels"
              copy="A sales funnel created by a Certified Coach is proven to generate leads, close sales and increase revenue for your business while you sleep."
            />
            <GlassCard
              onClick={() =>
                this.onBlockClick({
                  type: 'skills',
                  name: 'Website Development',
                })
              }
              Svg={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                  <path
                    d="M4.9056 34.7273L38.2389 34.7273C40.0799 34.7273 41.5723 33.0992 41.5723 31.0909V5.63639C41.5723 3.62808 40.0799 2.00002 38.2389 2.00002L4.9056 2.00002C3.06465 2.00002 1.57227 3.62808 1.57227 5.63639V31.0909C1.57227 33.0992 3.06465 34.7273 4.9056 34.7273Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M28.2391 42H14.9058"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.57227 25.6364H41.5723"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.5723 34.7273V42"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              title="Website Development"
              copy="From copywriting to design to full site development, a StoryBrand Certified Coach can create a website for you
              that will actually grow your business."
            />
            <GlassCard
              onClick={() =>
                this.onBlockClick({
                  type: 'skills',
                  name: 'Overall Marketing Strategy',
                })
              }
              Svg={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                  <path
                    d="M7.9359 42C11.4504 42 14.2995 39.1509 14.2995 35.6363C14.2995 32.1218 11.4504 29.2727 7.9359 29.2727C4.42136 29.2727 1.57227 32.1218 1.57227 35.6363C1.57227 39.1509 4.42136 42 7.9359 42Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.57227 9.27271L10.6632 18.3636"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.6632 9.27271L1.57227 18.3636"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M32.4814 31.0909L41.5724 40.1819"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M41.5724 31.0909L32.4814 40.1819"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M23.3906 11.0909V2H32.4815"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M23.3904 2L27.0268 5.63636C36.1177 14.7273 28.845 25.6364 21.5723 27.4545"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              title="Marketing Strategy"
              copy="Looking for a coach to help guide your marketing strategy and development? These experts can help."
            />
            <GlassCard
              onClick={() =>
                this.onBlockClick({
                  type: 'skills',
                  name: 'Email Marketing',
                })
              }
              Svg={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                  <path
                    d="M15.4184 27.6H7.72607"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M27.7261 26V2H33.8799"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M41.5723 32.4V20.4C41.5723 17.6417 40.5187 14.9965 38.6433 13.0461C36.768 11.0957 34.2244 10 31.5723 10H11.5723C14.2244 10 16.768 11.0957 18.6433 13.0461C20.5187 14.9965 21.5723 17.6417 21.5723 20.4V34H40.0338C40.4418 34 40.8331 33.8314 41.1217 33.5314C41.4102 33.2313 41.5723 32.8243 41.5723 32.4Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.5723 42V34H3.11073C2.7027 34 2.31139 33.8314 2.02287 33.5314C1.73435 33.2313 1.57227 32.8243 1.57227 32.4V20.4C1.57227 17.6417 2.62583 14.9965 4.5012 13.0461C6.37656 11.0957 8.9201 10 11.5723 10"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              title="Email Marketing"
              copy="These Coaches can write email copy, develop a campaign and implement it into your CRM of choice."
            />
            <GlassCard
              onClick={() =>
                this.onBlockClick({
                  type: 'skills',
                  name: 'Full Suite Marketing Agency',
                })
              }
              Svg={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                  <path
                    d="M11.5724 37C15.2543 37 18.2391 34.0152 18.2391 30.3333C18.2391 26.6514 15.2543 23.6666 11.5724 23.6666C7.89053 23.6666 4.90576 26.6514 4.90576 30.3333C4.90576 34.0152 7.89053 37 11.5724 37Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.5724 15.3333C15.2543 15.3333 18.2391 12.3486 18.2391 8.66667C18.2391 4.98477 15.2543 2 11.5724 2C7.89053 2 4.90576 4.98477 4.90576 8.66667C4.90576 12.3486 7.89053 15.3333 11.5724 15.3333Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M31.5724 37C35.2543 37 38.2391 34.0152 38.2391 30.3333C38.2391 26.6514 35.2543 23.6666 31.5724 23.6666C27.8905 23.6666 24.9058 26.6514 24.9058 30.3333C24.9058 34.0152 27.8905 37 31.5724 37Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.57227 42C2.7366 40.4475 4.2464 39.1875 5.9821 38.3197C7.71779 37.4518 9.6317 37 11.5723 37C13.5128 37 15.4267 37.4518 17.1624 38.3197C18.8981 39.1875 20.4079 40.4475 21.5723 42C22.7366 40.4475 24.2464 39.1875 25.9821 38.3197C27.7178 37.4518 29.6317 37 31.5723 37C33.5128 37 35.4267 37.4518 37.1624 38.3197C38.8981 39.1875 40.4079 40.4475 41.5723 42"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M31.5724 15.3333C35.2543 15.3333 38.2391 12.3486 38.2391 8.66667C38.2391 4.98477 35.2543 2 31.5724 2C27.8905 2 24.9058 4.98477 24.9058 8.66667C24.9058 12.3486 27.8905 15.3333 31.5724 15.3333Z"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.57227 20.3334C2.7366 18.7809 4.2464 17.5209 5.9821 16.653C7.71779 15.7852 9.6317 15.3334 11.5723 15.3334C13.5128 15.3334 15.4267 15.7852 17.1624 16.653C18.8981 17.5209 20.4079 18.7809 21.5723 20.3334C22.7366 18.7809 24.2464 17.5209 25.9821 16.653C27.7178 15.7852 29.6317 15.3334 31.5723 15.3334C33.5128 15.3334 35.4267 15.7852 37.1624 16.653C38.8981 17.5209 40.4079 18.7809 41.5723 20.3334"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              title="Full Suite 
Marketing Agency"
              copy="Hire an entire StoryBrand Certified team to execute a complete marketing strategy."
            />
          </div>
        </section>
        <section className="home-section relative z-10 p-16 flex flex-col items-center justify-center gap-16 lg:mt-24">
          <h2 className="text-center max-w-4xl">
            People just like you have seen their business grow after hiring a
            <span className="gold-text-gradient"> StoryBrand Certified Coach</span>
          </h2>
          <div className="grid md:grid-cols-3 grid-cols-1 place-items-center gap-4  w-full">
            <HiringStep
              Svg={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                  <path
                    d="M33.75 64.5248C51.0088 64.5248 64.9999 50.5337 64.9999 33.2749C64.9999 16.016 51.0088 2.0249 33.75 2.0249C16.4911 2.0249 2.5 16.016 2.5 33.2749C2.5 50.5337 16.4911 64.5248 33.75 64.5248Z"
                    stroke="black"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M55.8477 55.3726L77.5 77.0249"
                    stroke="black"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              title="Search"
              copy="Find the provider that’s right for 
your needs and your industry"
            />
            <HiringStep
              Svg={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="84" height="85" viewBox="0 0 84 85" fill="none">
                  <path
                    d="M42 82.5249C64.0914 82.5249 82 64.6163 82 42.5249C82 20.4335 64.0914 2.5249 42 2.5249C19.9086 2.5249 2 20.4335 2 42.5249C2 64.6163 19.9086 82.5249 42 82.5249Z"
                    stroke="black"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M42.0002 55.8582C51.2049 55.8582 58.6668 48.3963 58.6668 39.1916C58.6668 29.9868 51.2049 22.5249 42.0002 22.5249C32.7954 22.5249 25.3335 29.9868 25.3335 39.1916C25.3335 48.3963 32.7954 55.8582 42.0002 55.8582Z"
                    stroke="black"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.25 72.263C17.758 67.3231 21.5848 63.1741 26.3064 60.276C31.028 57.3778 36.4599 55.8438 42 55.8438C47.5401 55.8438 52.972 57.3778 57.6936 60.276C62.4152 63.1741 66.242 67.3231 68.75 72.263"
                    stroke="black"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              title="Hire Your Coach"
              copy="Start your next marketing project with the guidance and help of a marketing expert"
            />
            <HiringStep
              Svg={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="82" height="85" viewBox="0 0 82 85" fill="none">
                  <path
                    d="M76.2001 12.125H5.8001C4.03279 12.125 2.6001 13.5577 2.6001 15.325V63.325C2.6001 65.0923 4.03279 66.525 5.8001 66.525H76.2001C77.9674 66.525 79.4001 65.0923 79.4001 63.325V15.325C79.4001 13.5577 77.9674 12.125 76.2001 12.125Z"
                    stroke="black"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M53.8003 66.5249L66.6003 82.5249"
                    stroke="black"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M28.1999 66.5249L15.3999 82.5249"
                    stroke="black"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M28.2002 40.9248V50.5248"
                    stroke="black"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M41 34.5249V50.5249"
                    stroke="black"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M53.8003 28.125V50.525"
                    stroke="black"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M41 12.1249V2.5249"
                    stroke="black"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              title="Get Results"
              copy="See results happen when you start using
              the right words and strategy"
            />
          </div>
          <Link
            to="/search"
            className="hiring-gold-border rounded-full p-6 self-start mx-auto"
            style={{
              textDecoration: 'none',
              fontWeight: 'bold',
            }}
          >
            Find a Coach
          </Link>
        </section>

        <section className="home-section relative z-10 flex flex-col justify-center items-center gap-6">
          <figure className="absolute">
            <svg xmlns="http://www.w3.org/2000/svg" width="4440" height="750" viewBox="0 0 1440 550" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M-174.183 240.153C-164.174 126.162 117.96 49.4288 373.514 7.801C556.087 -21.9389 723.935 48.1775 917.023 62.4032C1136.53 78.5752 1414.72 20.0832 1548.03 94.3335C1688.29 172.447 1579.37 284.142 1469.92 370.34C1366.26 451.982 1198.33 515.464 983.497 538.933C759.191 563.437 527.779 545.634 330.756 494.786C94.2124 433.737 -184.336 355.79 -174.183 240.153Z"
                fill="#F5F5F5"
              />
            </svg>
          </figure>
          <figure className="relative">
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="90" viewBox="0 0 80 81" fill="none">
              <circle cx="40" cy="40.1697" r="40" fill="black" />
              <path
                d="M32.873 39.1171C34.4674 39.8186 35.711 40.8709 36.6038 42.2739C37.4967 43.677 37.9431 45.3351 37.9431 47.2483C37.9431 49.9906 37.0821 52.2227 35.3602 53.9446C33.6383 55.6027 31.4381 56.4318 28.7596 56.4318C26.0811 56.4318 23.849 55.5708 22.0633 53.8489C20.3414 52.127 19.4805 49.9268 19.4805 47.2483C19.4805 45.9728 19.6399 44.6974 19.9588 43.4219C20.2777 42.1464 20.9792 40.2332 22.0633 37.6822L27.6117 23.907H37.0821L32.873 39.1171ZM55.4491 39.1171C57.0434 39.8186 58.287 40.8709 59.1799 42.2739C60.0727 43.677 60.5191 45.3351 60.5191 47.2483C60.5191 49.9906 59.6582 52.2227 57.9363 53.9446C56.2144 55.6027 54.0142 56.4318 51.3357 56.4318C48.6572 56.4318 46.425 55.5708 44.6394 53.8489C42.9175 52.127 42.0565 49.9268 42.0565 47.2483C42.0565 45.9728 42.216 44.6974 42.5348 43.4219C42.8537 42.1464 43.5552 40.2332 44.6394 37.6822L50.1877 23.907H59.6582L55.4491 39.1171Z"
                fill="white"
              />
            </svg>
          </figure>
          <div className="relative w-full flex flex-col items-center justify-center">
            <TestimonialCarousel
              items={[
                {
                  testimonial:
                    'Their expertise in setting up our Facebook Ad campaigns is fantastic. The results we have had from using their expertise has been outstanding!',
                  author: 'Marcia M.',
                },
                {
                  testimonial:
                    'My website needed a major overhaul. My StoryBrand Certified Guide walked me through the process and made my website something I was proud of. My online sales are the highest they’ve ever been.',
                  author: 'Hilary W.',
                },
                {
                  testimonial:
                    'This year, we partnered with a StoryBrand Certified Agency on two digital projects. The results have been literally beautiful and fruitful for our business.',
                  author: 'Nathan M.',
                },
              ]}
            />
            <Link
              to="/search"
              className="rounded-full p-6 mt-36 text-black hover:text-white hover:bg-black transition-all duration-75"
              style={{
                border: 'solid 2px black',
                textDecoration: 'none',
                fontWeight: 'bold',
              }}
            >
              Find a Coach
            </Link>
          </div>
        </section>

        <div className="mt-32 home-section relative z-10 flex xl:flex-row flex-col items-center justify-center xl:justify-between w-full xl:px-36 px-16 xl:py-28 py-16">
          <div className="get-certified-container">
            <h2 className="get-certified-heading">
              Are you a marketing professional looking to get <span className="gold-text-gradient">certified?</span>
            </h2>
            <p className="small-copy mt-12 xl:max-w-md w-full xl:text-left text-center">
              Do you enjoy getting serious results for your clients? If you have more than 2 years of marketing
              experience and want the opportunity to use the StoryBrand framework with your clients,{' '}
              <a
                target="_blank"
                href="https://storybrand.com/guide/"
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                }}
              >
                apply here
              </a>
              .
            </p>
            <a
              style={{ textDecoration: 'none' }}
              className="inline-block font-bold  hiring-gold-border p-6 rounded-full mt-12"
              target="_blank"
              href="https://storybrand.com/guide/"
            >
              Apply Now
            </a>
          </div>

          <img
            className="xl:block hidden"
            src={require('../../../assets/laptop-lady.jpeg').default}
            style={{
              minHeight: '500px',
              minWidth: '500px',
              maxHeight: '500px',
              maxWidth: '500px',
              objectFit: 'cover',
              borderRadius: '40px',
            }}
          />
        </div>
      </>
    )
  }
}

export default Home

{
  /* <img src={require('../../../assets/freelancerpromo.png').default} /> */
}
