import axios from 'axios/index'
import * as React from 'react'
import { Component } from 'react'
import TagManager from 'react-gtm-module'
import Reaptcha from 'reaptcha'

const profileFormSubmitEventArguments = {
  dataLayer: { event: 'submitProfileForm' },
  dataLayerName: 'CYMDataLayer',
}

class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      phone: '',
      email: '',
      preferred_contact: 'email',
      budget: '',
      industry: '',
      message: '',
      profile_id: props.profile.id,
      errors: null,
      sent: false,
    }
    this.captcha = React.createRef()
  }

  handlePhoneInput = event => {
    let val = event.target.value
    val = val.replace(/[^0-9]+/g, '')

    let num

    if (val.length > 13) {
      return
    }

    if (val.length > 12) {
      num = `${val.substring(0, 3)} (${val.substring(3, 6)}) ${val.substring(6, 9)} ${val.substring(9, val.length)}`
    } else if (val.length > 11) {
      num = `${val.substring(0, 2)} (${val.substring(2, 5)}) ${val.substring(5, 8)} ${val.substring(8, val.length)}`
    } else if (val.length > 10) {
      num = `${val.substring(0, 1)} (${val.substring(1, 4)}) ${val.substring(4, 7)} ${val.substring(7, val.length)}`
    } else if (val.length > 6) {
      num = `(${val.substring(0, 3)}) ${val.substring(3, 6)} ${val.substring(6, val.length)}`
    } else if (val.length > 3) {
      num = `(${val.substring(0, 3)}) ${val.substring(3, 6)}`
    } else {
      num = `${val.substring(0, 3)}`
    }

    num = num.trim()

    this.setState({
      phone: num,
    })
  }

  preferredContactChange = event => {
    this.setState({
      preferred_contact: event.target.value,
    })
  }

  render() {
    const { name, phone, email, budget, industry, message, errors, preferred_contact } = this.state

    return (
      <div className="Contact" id="contact">
        {!this.state.sent ? (
          <form className="grid grid-cols-2 p-8 bg-white rounded-md shadow-md gap-x-4 gap-y-8">
            <div className="col-span-2 md:col-span-1">
              <label htmlFor="name">
                Name <span className="required">*</span>
              </label>
              <input
                type="text"
                maxLength="64"
                id="name"
                required
                placeholder="Jane Appleseed"
                value={name}
                onChange={event => this.setState({ name: event.target.value })}
              />
              <div className="mt-1 ml-2 text-xs text-red-600 error">
                {errors && errors['name'] ? errors['name'] : null}
              </div>
            </div>

            <div className="col-span-2 md:col-span-1">
              <label htmlFor="email">
                Email <span className="required">*</span>
              </label>
              <input
                type="email"
                maxLength="64"
                id="email"
                required
                placeholder="jane@gmail.com"
                value={email}
                onChange={event => this.setState({ email: event.target.value })}
              />
              <div className="mt-1 ml-2 text-xs text-red-600 error">
                {errors && errors['email'] ? errors['email'] : null}
              </div>
            </div>

            <div className="col-span-2 md:col-span-1">
              <label htmlFor="budget">Budget</label>
              <input
                type="text"
                maxLength="64"
                id="budget"
                placeholder="Enter budget"
                value={budget}
                onChange={event => this.setState({ budget: event.target.value })}
              />
            </div>
            <div className="col-span-2 md:col-span-1">
              <label htmlFor="industry">Industry</label>
              <input
                type="text"
                maxLength="64"
                id="industry"
                placeholder="Healthcare, finance, retail..."
                value={industry}
                onChange={event => this.setState({ industry: event.target.value })}
              />
            </div>

            <div className="col-span-2">
              <label htmlFor="comment">
                Message <span className="required">*</span>
              </label>
              <textarea
                id="comment"
                required
                onChange={event => this.setState({ message: event.target.value })}
                value={message}
                className="h-32"
              />
              <div className="mt-1 ml-2 text-xs text-red-600 error">
                {errors && errors['message'] ? errors['message'] : null}
              </div>
            </div>
            <div className="col-span-2 text-center">
              <button className="w-1/2 uppercase Button Button--gold" onClick={this.submitLead} type="submit">
                Send
              </button>
              <Reaptcha
                ref={e => (this.captcha = e)}
                sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                size="invisible"
                onVerify={this.submitLeadOnVerified}
              />
            </div>
          </form>
        ) : (
          <div>
            <h1>Thanks</h1>
            <p>Your message to {this.props.profile.first_name} has been sent!</p>
          </div>
        )}
      </div>
    )
  }

  getErrors = () => {
    var errors = null
    var emailRe = /\S+@\S+\.\S+/

    if (this.state.name === '') {
      errors = {}
      errors['name'] = 'Name is required'
    }
    if (this.state.email === '') {
      if (!errors) {
        errors = {}
      }
      errors['email'] = 'Email is required'
    } else if (!emailRe.test(this.state.email)) {
      if (!errors) {
        errors = {}
      }
      errors['message'] = 'Email is invalid'
    }
    if (this.state.message === '') {
      if (!errors) {
        errors = {}
      }
      errors['message'] = 'Message is required'
    }
    // Not an error, but still validating sensical input
    if (this.state.phone.trim() === '') {
      this.setState({ preferred_contact: 'email' })
    }
    return errors
  }

  submitLeadOnVerified = token => {
    TagManager.dataLayer(profileFormSubmitEventArguments)

    const formData = this.state
    formData.token = token
    delete formData.errors
    delete formData.sent
    axios.post(process.env.REACT_APP_API_LOCATION + '/api/leads', this.state).then(() => this.setState({ sent: true }))
  }

  submitLead = e => {
    e.preventDefault()

    const errors = this.getErrors()
    this.setState({ errors: errors })
    if (!errors) {
      this.captcha.execute()
    }
  }
}

export default Contact
