import React, { useState } from 'react'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { ProfileModal } from '../../profile/components'
import { MassContact } from '../components'

const SendToCoaches = ({
  step,
  previousStep,
  questions,
  filters,
  selectedContacts,
  previouslySelectedContacts,
  batch,
  sendToMoreCoaches,
  formData,
  setFormData,
  handleSelectedContact,
  coachConsult,
}) => {
  const [open, setOpen] = useState(false)
  const [profileToView, setProfileToView] = useState(null)

  const openProfileModal = profile => {
    setProfileToView(profile)
    setOpen(true)
  }

  const onCloseModal = () => {
    setOpen(false)
  }

  return (
    <div className="Send-to-coaches">
      <p className="step-counter">Step {step} of 7</p>
      <section className="content">
        <div className="mainContent">
          <section className="contact">
            <MassContact
              profile={{}}
              previousStep={previousStep}
              selectedContacts={selectedContacts}
              previouslySelectedContacts={previouslySelectedContacts}
              openProfileModal={openProfileModal}
              filters={filters}
              batch={batch}
              question={questions[step - 1]}
              sendToMoreCoaches={sendToMoreCoaches}
              formData={formData}
              setFormData={setFormData}
              handleSelectedContact={handleSelectedContact}
              coachConsult={coachConsult}
            />
          </section>
        </div>
      </section>
      {step === 6 && (
        <div className="button-wrapper">
          <div className="selected-count">{selectedContacts.length} Coaches Selected</div>
        </div>
      )}
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        closeIcon={
          <div className="close-button" data-app="mms">
            <img src={require('../../../assets/Close-Icon.png').default} alt="Close Button" />
          </div>
        }
        classNames={{ modal: 'profileModal' }}
      >
        <ProfileModal isSelectable={false} handleAndClose={() => {}} profileToView={profileToView} />
      </Modal>
    </div>
  )
}

export default SendToCoaches
