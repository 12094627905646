import React, { Component } from 'react'
import Button from './Button'

export default class FormSearch extends Component {
  constructor(props) {
    super(props)

    this.formSearch = React.createRef()
  }

  // "State management"
  handleQueryChange = e => {
    this.props.onFilterChange('query', e.target.value)
  }

  handleClear = () => {
    this.props.onFilterChange('query', '')
  }

  // External component click registration
  handleClick = e => {
    if (!this.props.isActive) return
    if (this.formSearch && !this.formSearch.current.contains(e.target)) {
      this.props.setActive('')
    }
  }

  componentDidMount = () => {
    document.addEventListener('click', this.handleClick)
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClick)
  }

  render() {
    const { query, setActive } = this.props
    return (
      <div className="Input--wrapper Form--stack" ref={this.formSearch}>
        <label>Keyword Search</label>
        <input
          className="Input"
          type="text"
          id="searcher"
          name="query"
          placeholder="E.g. 'Web Design' or 'Copywriting'"
          value={query}
          onChange={this.handleQueryChange}
          onFocus={() => setActive('search')}
        />
        <Button className="Button--clear" onClick={this.handleClear} type="button"></Button>
      </div>
    )
  }
}
