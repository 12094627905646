import axios from 'axios'
import React, { Component } from 'react'
import { Route, Switch } from 'react-router'
import { Link, withRouter } from 'react-router-dom'
import { BadUrl, Header, Home } from './modules/home/components'
import { Profile } from './modules/profile/components'
import { Search } from './modules/search/components'

import './App.scss'
import SearchRefactor from './modules/searchRefactor'

class App extends Component {
  /**
   * Note: There's currently no clientside datastore in CYM,
   * so all of the search field / filter state lives here. When
   * you're down in the search components inside of <Home/> and
   * <Search/> know that that data is sourced from this
   * component's state.
   *
   * The search results state lives in the <Search/> component.
   *
   * @memberof App
   */
  state = {
    skills: [],
    allSkills: [],
    work_options: [],
    allWorkOptions: [],
    course_preferences: [],
    allCoursePreferences: [],
    industries: [],
    all_industries: [],
    business_types: [],
    allBusinessTypes: [],
    services: [],
    allServices: [],
    certs: [],
    allCerts: [],
    lat: '',
    lng: '',
    budget: '',
    query: '',
    name: '',
    country: '',
    locationDisplayName: '',
    locationData: null,
    locationAttempted: false,
    showFooter: true,
    appName: process.env.REACT_APP_NAME,
  }

  componentDidMount = () => {
    this.loadCertsList()

    // MMS only
    this.loadSkillsList()

    // HAC Only
    this.loadCoachFilters()
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      //For HubSpot data
      const _hsq = (window._hsq = window._hsq || [])

      // HubSpot Tracking on each history change
      _hsq.push(['setPath', location.pathname])
      _hsq.push(['trackPageView'])
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  loadSkillsList = () => {
    axios
      .get(process.env.REACT_APP_API_LOCATION + '/api/skills')
      .then(response => {
        let allSkills = response.data
        allSkills.push({ id: '', name: 'All Skills' })
        this.setState({ allSkills })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  loadCoachFilters = () => {
    axios
      .get(process.env.REACT_APP_API_LOCATION + '/api/coach_filters')
      .then(response => {
        let allWorkOptions = response.data.work_options
        let allServices = response.data.services
        let allCoursePreferences = response.data.course_preferences
        let allIndustries = response.data.industries
        let allBusinessTypes = response.data.business_types
        // res.push({ id: '', name: 'All Skills' })
        this.setState({ allWorkOptions, allServices, allCoursePreferences, allIndustries, allBusinessTypes })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  loadCertsList = () => {
    axios
      .get(process.env.REACT_APP_API_LOCATION + '/api/certifications')
      .then(response => {
        let allCerts = response.data
        this.setState({ allCerts })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  onFilterChange = (filter, value) => {
    const obj = {}
    obj[filter] = value
    return new Promise(resolve => this.setState(obj, resolve))
  }

  setFooterVisibility = showFooter => {
    this.setState({
      showFooter,
    })
  }

  render() {
    return (
      <div
        className={
          'App' + (this.props.location.pathname === '/' || this.props.location.pathname === '/podcast' ? ' home' : '')
        }
        data-app={'mms'}
      >
        <Header router={this.props.history} />
        <div className="App-wrap">
          <Switch>
            <Route
              render={() => (
                <Home onFilterChange={this.onFilterChange} filters={this.state} router={this.props.history} />
              )}
              path="/"
              exact
            />
            <Route
              render={() => (
                <Home onFilterChange={this.onFilterChange} filters={this.state} router={this.props.history} />
              )}
              path="/podcast"
              exact
            />
            <Route render={() => <SearchRefactor />} path="/search" exact />
            <Route
              render={() => (
                <Search
                  onFilterChange={this.onFilterChange}
                  setFooterVisibility={this.setFooterVisibility}
                  filters={this.state}
                />
              )}
              path="/admin/search"
              exact
            />
            <Route
              render={() => (
                <Search
                  onFilterChange={this.onFilterChange}
                  setFooterVisibility={this.setFooterVisibility}
                  filters={this.state}
                />
              )}
              path="/search/coach-consult"
              exact
            />
            <Route
              render={() => (
                <Search
                  onFilterChange={this.onFilterChange}
                  setFooterVisibility={this.setFooterVisibility}
                  filters={this.state}
                />
              )}
              path="/name"
              exact
            />
            <Route
              path="/customer-testimonials/:id"
              render={props => {
                window.location = 'https://admin.marketingmadesimple.com/customer-testimonials/' + props.match.params.id
              }}
            />
            <Route
              path="/sign-up/:id"
              render={props => {
                window.location = 'https://admin.marketingmadesimple.com/sign-up/' + props.match.params.id
              }}
            />
            <Route path="/404" component={BadUrl} exact />
            <Route path="/:slug" render={props => <Profile {...props} />}></Route>
          </Switch>
        </div>
        {this.state.showFooter && (
          <footer className="cf" data-ie="isIE ? 'ie' : '">
            <div data-st="st-row" className="pw">
              <div data-st="st-six" className="cf">
                <div className="logo" data-app={'mms'}></div>
                <p>
                  Copyright &copy; {new Date().getFullYear()} Donald Miller Words, LLC. All rights reserved. <br />
                  <strong>615-915-1541</strong> &bull;{' '}
                  <a href={'https://storybrand.com/terms-and-conditions/'}>Terms and Conditions</a>{' '}
                  <a style={{ marginLeft: '5px' }} href={'https://storybrand.com/privacy-policy/'}>
                    Privacy Policy.
                  </a>
                </p>
              </div>
              <div data-st="st-six" role="menu">
                <nav>
                  <ul>
                    <li>
                      <a href="https://storybrand.com/guide/" target="_blank" rel="noopener noreferrer">
                        Get Certified
                      </a>
                    </li>
                    <li>
                      <a href="https://storybrand.com/live/">Live Workshops</a>
                    </li>
                    <li>
                      <a href="https://storybrand.com/privateworkshops/">Private Workshops</a>
                    </li>

                    <li>
                      <a href="https://help.businessmadesimple.com/">Help</a>
                    </li>
                    <li>
                      <a href="https://businessmadesimple.com/online-services-cancellation-policy">
                        Cancellation Policy
                      </a>
                    </li>
                    <li>
                      <a href="https://businessmadesimple.com/digital-millennium-copyright-act-policy">DMCA</a>
                    </li>

                    <li>
                      <a href={'https://storybrand.com/contact/'}>Contact</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </footer>
        )}
      </div>
    )
  }
}

function MMSHeader({ handleClose }) {
  return (
    <div data-st="st-six" role="menu">
      <nav data-app={'mms'} className="bg-red-300">
        <ul className="text-white text-base leading-8 font-bold flex gap-8">
          <li className="flex flex-nowrap items-center gap-4 text-white">
            The Sales Funnel Plan{' '}
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M9 11.1818V1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M17 11.1818V17H1V11.1818"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.6365 7.54546L9.00013 11.1818L5.36377 7.54546"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </figure>
          </li>
          <li className="text-white">Get Certified</li>
          <li className="text-white">Search Now</li>
          <li>
            <Link className="text-white hiring-gold-border-dark p-4 rounded-full no-underline">Login</Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default withRouter(App)
{
  /* <nav className="flex justify-between items-center">
            <img
              className="h-32 w-32 object-cover"
              src={require('./assets/mms-new-logo.png').default}
              alt="Marketing Made Simple"
            />
            <ul className="text-white text-base leading-8 font-bold flex gap-8">
              <li className="flex items-center gap-4 text-white">
                The Sales Funnel Plan{' '}
                <figure>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path
                      d="M9 11.1818V1"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17 11.1818V17H1V11.1818"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.6365 7.54546L9.00013 11.1818L5.36377 7.54546"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </figure>
              </li>
              <li className="text-white">Get Certified</li>
              <li className="text-white">Search Now</li>
              <li>
                <Link className="text-white hiring-gold-border-dark p-4 rounded-full no-underline">Login</Link>
              </li>
            </ul>
          </nav> */
}
