import React, { Fragment, useEffect, useRef } from 'react'
import {
  ADD_TO_SELECTED_FILTER_IDS,
  ADD_TO_SELECTED_FILTER_WITH_IDS,
  REMOVE_SELECTED_FILTER_IDS,
  UPDATE_BUDGET,
} from './index'
import { Checkbox, Input, Label } from './UI/Forms'

function GridContainer({ title, isSubTitle = false, cols, colSpan = '', children }) {
  return (
    <div className={`w-full ${colSpan}`}>
      <Label htmlFor={title} large={!isSubTitle} small={isSubTitle}>
        {title}
      </Label>
      <div className={`grid ${cols} gap-3 place-items-start`}>{children}</div>
    </div>
  )
}

function BudgetOption({ id, handleChange, tooltipText, labelText }) {
  return (
    <Fragment>
      <input type="radio" id={id} name="price" onChange={handleChange} />
      <label htmlFor={id}>
        {labelText}
        <div className="tooltip">{tooltipText}</div>
      </label>
    </Fragment>
  )
}

function Budget({ handleBudgetFilter }) {
  return (
    <div className="flex">
      <span className="ratings ratings--dollar">
        <BudgetOption tooltipText={'Projects starting at $10,000'} id="price_4" handleChange={handleBudgetFilter} />
        <BudgetOption tooltipText={'Projects starting at $5,000'} id="price_3" handleChange={handleBudgetFilter} />
        <BudgetOption tooltipText={'Projects starting at $1,500'} id="price_2" handleChange={handleBudgetFilter} />
        <BudgetOption tooltipText={'Projects starting at $5000'} id="price_1" handleChange={handleBudgetFilter} />
      </span>
      <BudgetOption labelText="ALL" id="price_all" handleChange={handleBudgetFilter} />
    </div>
  )
}

function MMSFilters({
  filters,
  dispatch,
  handleStringFilter,
  handleCheckBoxFilter,
  selectedFilters,
  selectedFiltersCount,
}) {
  function test(e) {
    const { id } = e.target
    //get the value
    const split = id.split('_')[1]
    //handle reset
    if (split === 'all') {
      //reset
      const { budget, ...rest } = selectedFilters
      dispatch({
        type: UPDATE_BUDGET,

        payload: { selectedFilters: rest, selectedFiltersCount: Math.max(selectedFiltersCount - 1, 0) },
      })
    } else {
      //update the budget value
      const updatedSelectedFilters = {
        ...selectedFilters,
        budget: split,
      }

      let countToSet = selectedFiltersCount
      if (!('budget' in selectedFilters)) {
        //increment
        countToSet += 1
      }

      //update state
      dispatch({
        type: UPDATE_BUDGET,
        payload: { selectedFilters: updatedSelectedFilters, selectedFiltersCount: countToSet },
      })
    }
  }
  return (
    <div className="flex flex-col space-y-6">
      <GridContainer title="Name" cols={'grid-cols-1'}>
        <Input
          type="text"
          placeholder="Search by name"
          onChange={e => handleStringFilter(e, true)}
          onFocus={() => {}}
          id={'name'}
        />
      </GridContainer>

      {/* <GridContainer title="Budget" cols={'grid-cols-1'}>
        <Budget handleBudgetFilter={test} />
      </GridContainer> */}

      <GridContainer title="Additional Certifications" cols={'lg:grid-cols-4 sm:grid-cols-2 grid-cols-1'}>
        {filters.certifications.map((certification, idx) => (
          <Checkbox
            key={certification.id}
            stateIdentifier={'certifications'}
            onChange={handleCheckBoxFilter}
            id={certification.id}
            name={certification.name}
            defaultChecked={selectedFilters['certifications']?.includes(certification.id)}
          />
        ))}
      </GridContainer>
      <GridContainer title="Services and Products Offered" cols={'grid-cols-3'}>
        {filters.skills.map((skill, idx) => (
          <Checkbox
            key={skill.id}
            stateIdentifier={'skills'}
            onChange={handleCheckBoxFilter}
            id={skill.id}
            name={skill.name}
            defaultChecked={selectedFilters['skills']?.includes(skill.id)}
          />
        ))}
      </GridContainer>
      <GridContainer title="Industry Specialty" cols={'lg:grid-cols-3 grid-cols-1'}>
        {filters.industries.map(industry => (
          <Checkbox
            key={industry.id}
            stateIdentifier={'industries'}
            onChange={handleCheckBoxFilter}
            id={industry.id}
            name={industry.name}
            defaultChecked={selectedFilters['industries']?.includes(industry.id)}
          />
        ))}
      </GridContainer>
      <GridContainer title="Your Company Annual Revenue" cols={'grid-cols-1'}>
        {filters.companyAnnualRevenues.map(car => (
          <Checkbox
            key={car.id}
            stateIdentifier={'companyAnnualRevenues'}
            onChange={handleCheckBoxFilter}
            id={car.id}
            name={car.amount}
            defaultChecked={selectedFilters['companyAnnualRevenues']?.includes(car.id)}
          />
        ))}
      </GridContainer>

      <GridContainer title="Additional Filters" cols={'lg:grid-cols-3 grid-cols-1'}>
        {filters.attributes.map(attribute => (
          <Checkbox
            key={attribute.id}
            stateIdentifier={'attributes'}
            onChange={handleCheckBoxFilter}
            id={attribute.id}
            name={attribute.name}
            defaultChecked={selectedFilters['attributes']?.includes(attribute.id)}
          />
        ))}
      </GridContainer>
    </div>
  )
}

export default function ModalFilters({ open, handleClose, state, dispatch, handleStringFilter, triggerFormSubmit }) {
  const ref = useRef()

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && event.target.id !== 'clear' && !ref.current.contains(event.target) && open) {
        handleClose()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, open, handleClose])

  useEffect(() => {
    window.addEventListener('keypress', triggerFormSubmit)

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', triggerFormSubmit)
    }
  }, [])

  function handleCheckBoxFilter(stateIdentifier, id) {
    //check if there is a selectedFilter present
    if (stateIdentifier in state.selectedFilters) {
      //if the id exists the user has unchecked the given filter
      const hasId = state.selectedFilters[stateIdentifier].includes(id)
      if (hasId) {
        const copy = state.selectedFilters
        //if there's only one ID just remove the filter entirely
        if (copy[stateIdentifier].length === 1) {
          delete copy[stateIdentifier]
        } else {
          //remove the id from the array of ids
          const filtered = copy[stateIdentifier].filter(sId => sId !== id)
          //attach new filtered array
          copy[stateIdentifier] = filtered
        }

        //send back the updated array
        dispatch({
          type: REMOVE_SELECTED_FILTER_IDS,
          payload: { selectedFilters: copy, selectedFiltersCount: state.selectedFiltersCount - 1 },
        })
      } else {
        //tack on to the ids
        dispatch({ type: ADD_TO_SELECTED_FILTER_IDS, payload: { filterKey: stateIdentifier, filterID: id } })
      }
    } else {
      //brand new selected filter -Send ID and the type of filter to add
      dispatch({ type: ADD_TO_SELECTED_FILTER_WITH_IDS, payload: { filterKey: stateIdentifier, id } })
    }
  }
  return (
    <div
      ref={ref}
      style={{ width: '97%', maxHeight: '700px' }}
      className={`bg-white p-8 rounded-xl border border-solid border-gray-200 overflow-y-scroll absolute lg:top-28 top-80
      lg:w-11/12   z-10  transform transition-all duration-300 ${
        open ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-5 invisible'
      }`}
    >
      <MMSFilters
        key={state.refreshFilterCount}
        filters={state.filters}
        selectedFilters={state.selectedFilters}
        selectedFiltersCount={state.selectedFiltersCount}
        dispatch={dispatch}
        handleStringFilter={handleStringFilter}
        handleCheckBoxFilter={handleCheckBoxFilter}
      />
    </div>
  )
}
