import axios from 'axios/index'
import Cookies from 'js-cookie'
import * as React from 'react'
import { Component, Fragment } from 'react'
import TagManager from 'react-gtm-module'
import Reaptcha from 'reaptcha'
import Button from '../../shared/Button'

const profileFormSubmitEventArguments = {
  dataLayer: { event: 'submitProfileForm' },
  dataLayerName: 'CYMDataLayer',
}

class MassContact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: this.getSavedFormData('name'),
      phone: this.getSavedFormData('phone'),
      email: this.getSavedFormData('email'),
      preferred_contact: this.getSavedFormData('preferred_contact'),
      budget: '',
      industry: '',
      message: '',
      profile_id: props.profile.id,
      errors: null,
      loading: false,
      sent: false,
      profiles: this.getSelectedContacts(),
      selectedContacts: this.markSelectedContacts(),
      filters: this.props.filters,
      batch: this.props.batch,
      coachConsult: this.props.coachConsult,
    }
    this.captcha = React.createRef()
  }

  getSavedFormData = item => {
    const { formData } = this.props
    if (formData && formData[item]) {
      return formData[item]
    }

    if (item === 'preferred_contact') return 'email'

    return ''
  }

  getSelectedContacts = () => {
    const { selectedContacts, previouslySelectedContacts } = this.props
    const search = (data, terms) => {
      terms = new Set(terms)
      return data.filter(({ id }) => !terms.has(id))
    }
    const previousIds = previouslySelectedContacts.map(item => item.id)
    const filtered = search(selectedContacts, previousIds)

    return filtered
  }

  markSelectedContacts = () => {
    const { selectedContacts, previouslySelectedContacts } = this.props
    const search = (data, terms) => {
      terms = new Set(terms)
      return data.map(item => {
        if (terms.has(item.id)) {
          item.alreadySelected = true
        }
        return item
      })
    }
    const previousIds = previouslySelectedContacts.map(item => item.id)
    const filtered = search(selectedContacts, previousIds)

    return filtered
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.selectedContacts !== prevProps.selectedContacts) {
      this.setState({ selectedContacts: this.markSelectedContacts() })
    }
  }

  handlePhoneInput = event => {
    let val = event.target.value
    val = val.replace(/[^0-9]+/g, '')

    let num

    if (val.length > 13) {
      return
    }

    if (val.length > 12) {
      num = `${val.substring(0, 3)} (${val.substring(3, 6)}) ${val.substring(6, 9)} ${val.substring(9, val.length)}`
    } else if (val.length > 11) {
      num = `${val.substring(0, 2)} (${val.substring(2, 5)}) ${val.substring(5, 8)} ${val.substring(8, val.length)}`
    } else if (val.length > 10) {
      num = `${val.substring(0, 1)} (${val.substring(1, 4)}) ${val.substring(4, 7)} ${val.substring(7, val.length)}`
    } else if (val.length > 6) {
      num = `(${val.substring(0, 3)}) ${val.substring(3, 6)} ${val.substring(6, val.length)}`
    } else if (val.length > 3) {
      num = `(${val.substring(0, 3)}) ${val.substring(3, 6)}`
    } else {
      num = `${val.substring(0, 3)}`
    }

    num = num.trim()

    this.setState({
      phone: num,
    })
  }

  preferredContactChange = event => {
    this.setState({
      preferred_contact: event.target.value,
    })
  }

  render() {
    const { name, phone, email, errors, preferred_contact, selectedContacts, coachConsult } = this.state
    const { openProfileModal, sendToMoreCoaches, handleSelectedContact } = this.props

    return (
      <div className={`Contact mass-send`} id="contact">
        {!this.state.sent ? (
          <Fragment>
            <h1 className="--color-black">{this.props.question}</h1>
            <p>
              Fill out the form below to send your info to your selected coach
              {selectedContacts.length === 1 ? '' : 'es'}. They will contact you about next steps.
            </p>
            <form className={'grid grid-cols-2 p-8 bg-white rounded-md shadow-md gap-x-4 gap-y-8'}>
              <div className="col-span-2 md:col-span-1">
                <label htmlFor="name">
                  Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  maxLength="64"
                  id="name"
                  required
                  placeholder="Jane Appleseed"
                  value={name}
                  onChange={event => this.setState({ name: event.target.value })}
                />
                <div className="mt-1 ml-2 text-xs text-red-600 error">
                  {errors && errors['name'] ? errors['name'] : null}
                </div>
              </div>

              <div className="col-span-2 md:col-span-1">
                <label htmlFor="email">
                  Email <span className="required">*</span>
                </label>
                <input
                  type="email"
                  maxLength="64"
                  id="email"
                  required
                  placeholder="jane@gmail.com"
                  value={email}
                  onChange={event => this.setState({ email: event.target.value })}
                />
                <div className="mt-1 ml-2 text-xs text-red-600 error">
                  {errors && errors['email'] ? errors['email'] : null}
                </div>
              </div>

              <div className="col-span-2">
                <div className="text-with-check">
                  {selectedContacts && selectedContacts.length ? (
                    <Fragment>
                      <img src={require('../../../assets/GreenCheckMark.png').default} alt="Check Mark" />
                      <p className="">Your custom form selections are attached</p>
                    </Fragment>
                  ) : (
                    <p className="">You have not selected any coaches yet.</p>
                  )}
                </div>
                {coachConsult && (
                  <div className="text-with-check">
                    <img src={require('../../../assets/GreenCheckMark.png').default} alt="Check Mark" />
                    <p className="">You are redeeming your free 30 minute coaching call</p>
                  </div>
                )}
              </div>

              {selectedContacts.length ? (
                <div className="mb-2 col-span-2">
                  <p>Coach{selectedContacts.length > 1 ? 'es' : ''} Selected</p>
                  {selectedContacts.map(item => (
                    <div className="Flex" key={item.id}>
                      <button
                        type="button"
                        key={item.id}
                        className="Button--plain Button--plain__narrow"
                        onClick={() => openProfileModal(item)}
                      >
                        <div className="text-with-check">
                          <img src={require('../../../assets/BigGold-Check.png').default} alt="Big Gold Check Mark" />
                          <p className="">
                            {item.first_name + ' ' + item.last_name}
                            <span>
                              <small>{item.alreadySelected ? ' (Already Contacted)' : ''}</small>
                            </span>
                          </p>
                        </div>
                      </button>
                      <button
                        type="button"
                        onClick={() => handleSelectedContact(item)}
                        className="Button Button--plain Button--plain__close"
                      >
                        <img
                          src={require('../../../assets/GreyX.png').default}
                          alt="Close Button"
                          style={{ height: '13px' }}
                        />
                      </button>
                    </div>
                  ))}
                </div>
              ) : null}

              <div className="col-span-2 flex">
                <div className="error">{errors && errors['message'] ? errors['message'] : null}</div>
                <Button className="Button Button--ghost flex-1" onClick={this.props.previousStep}>
                  Back
                </Button>
              </div>
              <div className="col-span-2 flex">
                <button
                  className="Button Button--gold flex-1"
                  onClick={this.submitLeads}
                  type="submit"
                  disabled={(selectedContacts && !selectedContacts.length) || this.state.loading}
                >
                  SEND{this.state.loading ? 'ING...' : ''}
                </button>
              </div>
              <Reaptcha
                ref={e => (this.captcha = e)}
                sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                size="invisible"
                onVerify={this.submitLeadOnVerified}
              />
            </form>
          </Fragment>
        ) : (
          <div>
            <h1 className="--color-black">Success!</h1>
            <p>
              You’ve taken the first step in developing yourself! Keep an eye on your inbox. You’ll be contacted by the
              coach{coachConsult ? '' : 'es'} you selected regarding next steps.
            </p>
            <p>
              Your {coachConsult ? '' : 'potential '}coach{coachConsult ? '' : 'es'}:
            </p>
            {selectedContacts.length ? (
              <div className="mb-2">
                {selectedContacts.map(item => (
                  <button key={item.id} className="Button--plain" onClick={() => openProfileModal(item)}>
                    <div className="text-with-check">
                      <img src={require('../../../assets/BigGold-Check.png').default} alt="Big Gold Check Mark" />
                      <p className="">{item.first_name + ' ' + item.last_name}</p>
                    </div>
                  </button>
                ))}
              </div>
            ) : null}
            <div className="Flex--justify-center mb-2">
              <Button className="Button Button--gold" onClick={sendToMoreCoaches}>
                {coachConsult ? 'Go to hireacoach.com' : 'Send your info to more coaches'}
              </Button>
            </div>
            {!coachConsult && (
              <div className="Card__more-coaches">
                <button onClick={() => window.location.reload()}>Back To Hire A Coach</button>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  getErrors = () => {
    var errors = null
    var emailRe = /\S+@\S+\.\S+/

    if (this.state.name === '') {
      errors = {}
      errors['name'] = 'Name is required'
    }
    if (this.state.email === '') {
      if (!errors) {
        errors = {}
      }
      errors['email'] = 'Email is required'
    } else if (!emailRe.test(this.state.email)) {
      if (!errors) {
        errors = {}
      }
      errors['message'] = 'Email is invalid'
    }

    // Not an error, but still validating sensical input
    if (this.state.phone.trim() === '') {
      this.setState({ preferred_contact: 'email' })
    }
    return errors
  }

  submitLeadOnVerified = token => {
    this.setState({ loading: true })
    TagManager.dataLayer(profileFormSubmitEventArguments)

    if (this.state.coachConsult) {
      Cookies.set('sb-hac-cc2', '1', { expires: 90 })
    }

    const formData = this.state
    this.props.setFormData(formData)
    formData.token = token
    delete formData.errors
    delete formData.sent
    axios
      .post(process.env.REACT_APP_API_LOCATION + '/api/mass/leads', this.state)
      .then(() => this.setState({ sent: true, loading: false }))
  }

  submitLeads = e => {
    e.preventDefault()

    const errors = this.getErrors()
    this.setState({ errors: errors })
    if (!errors) {
      this.captcha.execute()
    }
  }
}

export default MassContact
