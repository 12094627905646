import React, { Component } from 'react'
import { Budget, Certifications, Skills } from '../search/components/filters'
import Button from './Button'

export default class FormFilters extends Component {
  constructor(props) {
    super(props)

    this.formFilters = React.createRef()
  }

  // Get active filters
  filterCount = () => {
    const toInt = f => {
      return f.length ? 1 : 0
    }

    const { name, budget, skills, certs } = this.props.filters
    let count = toInt(name) + toInt(budget) + skills.length + certs.length
    return count
  }

  // "State management"
  handleNameChange = e => {
    this.props.onFilterChange('name', e.target.value)
  }

  handleClear = () => {
    this.props.onFilterChange('name', '')
    this.props.onFilterChange('budget', '')
    this.props.onFilterChange('certs', [])
    this.props.onFilterChange('skills', [])
  }

  // External click registration from component
  handleClick = e => {
    if (!this.props.isActive) return
    if (this.formFilters && !this.formFilters.current.contains(e.target)) {
      this.props.setActive('')
    }
  }

  componentDidMount = () => {
    document.addEventListener('click', this.handleClick)
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClick)
  }

  render() {
    const { filters, onFilterChange, setActive } = this.props

    return (
      <div className="FormFilters" ref={this.formFilters}>
        <div className="Form--stack">
          <label>Filters</label>
          <input
            className="Input"
            placeholder={
              this.filterCount()
                ? `${this.filterCount()} ${this.filterCount() > 1 ? 'filters' : 'filter'} active`
                : 'Add filters'
            }
            onFocus={() => setActive('filters')}
            readOnly
          />

          <Button className="Button--clear" onClick={this.handleClear} type="button"></Button>
        </div>

        <div className="Form--modal FiltersModal" data-app="mms">
          <div className="FiltersModalContent">
            <div className="Filter">
              <h3>Name</h3>
              <input
                className="Input"
                type="text"
                placeholder="Enter Name"
                value={filters.name}
                onChange={this.handleNameChange}
              />
            </div>

            <div className="Filter">
              <h3>Budget</h3>
              <Budget onFilterChange={onFilterChange} budget={filters.budget} />
            </div>
            <div className="Filter">
              <h3>Additional Certifications</h3>
              <Certifications
                allCerts={filters.allCerts}
                onFilterChange={onFilterChange}
                selectedCerts={filters.certs}
              />
            </div>
            <div className="Filter">
              <h3>Skills</h3>
              <Skills allSkills={filters.allSkills} onFilterChange={onFilterChange} selectedSkills={filters.skills} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
