import axios from 'axios'
import T from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'
import React, { Component, Fragment } from 'react'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import Helmet from 'react-helmet'
import { Redirect } from 'react-router'
import Slider from 'react-slick'
import { disableChat } from '../../../utility/chat'
import { Contact, Testimonial } from '../components'

// Import css files
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

function CertificationsLoader() {
  return (
    <div className="flex items-start justify-start h-12 Profile">
      <div className="flex flex-col items-center">
        <span className="spinner"></span>
        <span className="block font-bold tracking-widest mt-4 uppercase text-md">Loading certifications...</span>
      </div>
    </div>
  )
}

function Tooltip({ children, Overlay }) {
  const handleClick = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <T
      class="rc-tool"
      placement="right"
      overlay={<Overlay />}
      arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
      overlayInnerStyle={{
        backgroundColor: '#E7C03A',
        border: 'solid 1px #fff',
        borderRadius: '14px',
        boxShadow: '0px 2px 14px 0px rgba(0, 0, 0, 0.1)',
        fontSize: '1rem',
      }}
      onClick={handleClick}
    >
      {children}
    </T>
  )
}

function BadgeToolTip({ certificationName, certificationCopy, imgSrc }) {
  return (
    <div className="relative flex items-center justify-center mb-0 text-base uppercase lg:justify-start">
      <Tooltip
        Overlay={() => (
          <p className="m-0 p-0 text-base uppercase">
            <span className="font-bold">{certificationName}</span> Certified {certificationCopy}
          </p>
        )}
      >
        <img className="w-16 h-16 mr-4" src={imgSrc} />
      </Tooltip>
    </div>
  )
}

const GRANT_WHITELIST = ['CB.STORYBRAND.CERTIFIED', 'MMS.LEGACY']
const CERTIFICATION_GRANTS_LOOKUP = {
  'CB.STORYBRAND.CERTIFIED': {
    imgSrc: require('../../../assets/sb-coach-badge.png').default,
    certificationName: 'StoryBrand',
    certificationCopy: 'Coach',
  },
  'MMS.LEGACY': {
    imgSrc: require('../../../assets/guide-badge-2020.svg').default,
    certificationName: 'StoryBrand',
    certificationCopy: 'Guide',
  },
}

function Certification({ imgSrc, certificationName, certificationCopy }) {
  return (
    <div className="relative flex lg:flex-row flex-col lg:items-center justify-start lg:mb-4 mb-0">
      <img className="md:w-16 md:h-16 w-24 h-24 mr-4 lg:mb-0 mb-2" src={imgSrc} />
      <p className="hidden md:inline-block uppercase m-0 p-0">
        <span className="font-bold">{certificationName}</span> Certified {certificationCopy}
      </p>
    </div>
  )
}

const profilePageLoadEventArguments = {
  dataLayer: { event: 'loadProfilePage' },
  dataLayerName: 'CYMDataLayer',
}

class Profile extends Component {
  constructor(props) {
    if (process.env.REACT_APP_GA_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID)
    }

    super(props)
    this.state = {
      profile: props.profile ? props.profile : null,
      referral_code: null,
      noMatch: false,
      fixAside: false,
      loadingGrants: true,
      profileGrants: [],
    }
    this.contactForm = React.createRef()

    this.slider = React.createRef()
    if (props.modalProfileId) {
      // this.loadProfile(props.modalProfileId)
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: document.title })
    } else {
      this.loadProfile(props.match.params.slug)
      this.loadGrants(props.match.params.slug)
    }

    TagManager.dataLayer(profilePageLoadEventArguments)
    disableChat()
  }

  handleResize = () => {
    const fixAside = window.innerWidth >= 1024
    if (this.state.fixAside !== fixAside) this.setState({ fixAside })
  }

  componentDidMount() {
    let profileSlug = this.props.modalProfileId ? this.props.modalProfileId : this.props.match.params.slug
    axios.post(process.env.REACT_APP_API_LOCATION + `/api/profile-view/${profileSlug}`)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidUpdate(prevProps) {
    if (this.props.modalProfileId) {
      return
    }
    let {
      location: { pathname },
    } = this.props

    if (prevProps.location.pathname === pathname) return

    this.loadProfile(this.props.match.params.slug)
    this.loadGrants(this.props.match.params.slug)
  }

  trackWebsiteClick = () => {
    axios.post(
      process.env.REACT_APP_API_LOCATION +
        `/api/external-website-view/${
          this.props.modalProfileId ? this.props.modalProfileId : this.props.match.params.slug
        }`
    )
  }

  loadGrants = slug => {
    axios
      //grab profile & grants
      .get(process.env.REACT_APP_API_LOCATION + '/api/profile-grants?slug=' + encodeURIComponent(slug))
      .then(response => {
        this.setState({ loadingGrants: false, profileGrants: response.data.grants })
      })
      .catch(function (error) {
        this.setState({ loadingGrants: false })
        console.log(error)
      })
  }

  loadProfile = slug => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: document.title })
    axios
      .get(process.env.REACT_APP_API_LOCATION + '/api/profiles?slug=' + encodeURIComponent(slug))
      .then(response => {
        if (response.data.total) {
          this.setState({ profile: response.data.data[0] })
        } else {
          this.setState({ noMatch: true })
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  loadReferral = slug => {
    axios
      .get(process.env.REACT_APP_API_LOCATION + `/api/profile/referral/${encodeURIComponent(slug)}`)
      .then(({ data, status }) => {
        if (status === 200 && !!data.success) {
          this.setState({ referral_code: data.referral_code })
        }
      })
  }

  render() {
    const { profile, referral_code, profileGrants } = this.state
    const { modalProfileId } = this.props

    if (this.state.noMatch) {
      return <Redirect to="/404" />
    }

    let averageCost = ''
    if (profile) {
      switch (profile.price) {
        case 1:
          averageCost = '$500'
          break
        case 2:
          averageCost = '$1,500'
          break
        case 3:
          averageCost = '$5,000'
          break
        case 4:
          averageCost = '$10,000'
          break
        default:
          averageCost = '$10,000'
      }
    }

    if (!this.state.profile) {
      return (
        <div className="flex items-center justify-center h-banner Profile">
          <div className="flex flex-col items-center">
            <span className="spinner"></span>
            <span className="block mt-6 font-bold tracking-widest uppercase text-md">Loading profile...</span>
          </div>
        </div>
      )
    }

    const renderMMS = () => {
      return (
        <div className="Profile">
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              {'Marketing Made Simple'} | {profile.first_name} {profile.last_name}
            </title>
          </Helmet>
          <section className="mb-12 lg:mb-24">
            <div className="3xl:w-8/12 2xl:mx-auto mx-none w-full">
              <img
                className="object-fit w-full max-h-96"
                alt="Custom Profile Image"
                src={
                  profile.hero_image_url ? profile.hero_image_url : require('../../../assets/hero-image.jpg').default
                }
              />
            </div>
            <div className="hidden px-6 py-4 bg-white border-b border-gray-200 w-100 lg:block">
              <div className="relative mx-auto max-w-7xl flex lg:pl-52">
                <div className="absolute left-0 w-48 h-48 overflow-hidden transform -translate-y-1/2 border border-gray-100 border-solid rounded-full shadow-sm top-1/2">
                  <img className="object-cover" src={profile.image_url} alt="Profile photo" />
                </div>

                {this.state.loadingGrants && <CertificationsLoader />}
                {this.state.profileGrants.map(certification => {
                  if (!GRANT_WHITELIST.includes(certification.grant)) return <Fragment></Fragment>
                  return (
                    <BadgeToolTip
                      key={`${certification.id}-desktop`}
                      imgSrc={CERTIFICATION_GRANTS_LOOKUP[certification.grant].imgSrc}
                      certificationName={CERTIFICATION_GRANTS_LOOKUP[certification.grant].certificationName}
                      certificationCopy={CERTIFICATION_GRANTS_LOOKUP[certification.grant].certificationCopy}
                    />
                  )
                })}
              </div>
            </div>
          </section>
          <section className="px-6 mb-12 md:px-10">
            <div className="grid grid-cols-1 mx-auto lg:grid-cols-3 max-w-7xl lg:gap-x-6">
              <aside className="block col-span-1 mb-12 lg:mb-0">
                <div className="grid items-center w-full grid-cols-4 mb-4 text-center gap-y-4 sm:gap-y-0 sm:gap-x-1 lg:hidden">
                  <div className="justify-center order-2 col-span-4 sm:col-span-1 sm:order-1">
                    {profile?.topCertification?.name.toLowerCase() === 'agency' ||
                    profile?.workshopCertified?.name === 'workshop facilitator' ? (
                      <div className="relative flex flex-col items-center lg:items-start">
                        {profile?.topCertification?.name.toLowerCase() === 'agency' && (
                          <span className="pill">
                            Agency
                            <div className="tooltip">
                              This entire team has been trained, so no matter who you talk to at their agency you'll be
                              in good hands! If you are a larger company looking for a full-suite agency, look no
                              further than one of ours!
                            </div>
                          </span>
                        )}
                        {profile?.workshopCertified?.name === 'workshop facilitator' && (
                          <span className="pill">
                            Facilitator
                            <div className="tooltip">
                              This Guide is equipped to put on a StoryBrand Private Workshop for your team. It's perfect
                              if you need to get everyone on the same page before moving forward with a big marketing
                              effort.
                            </div>
                          </span>
                        )}
                      </div>
                    ) : null}
                  </div>
                  <div className="order-1 col-span-4 sm:col-span-2 sm:order-2">
                    <div className="flex flex-col items-center">
                      <div className="relative flex justify-center mb-4">
                        <div className="w-48 h-48 overflow-hidden border border-gray-100 border-solid rounded-full shadow-sm">
                          <img className="object-cover" src={profile.image_url} alt="Profile photo" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="order-3 col-span-4 sm:col-span-1">
                    <div className="flex items-center justify-center text-center lg:hidden">
                      <div className="relative">
                        <label className={`price price--${profile.price}`}>
                          {Array.from({ length: 4 }).map(v => (
                            <span></span>
                          ))}
                        </label>
                        <span className="tooltip">Projects starting at {averageCost}</span>
                        <span className="block mt-2 text-xs font-bold uppercase">Average Cost</span>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="grid grid-cols-2 text-center gap-x-4 lg:block lg:text-left">
                  <div className="col-span-2">
                    <h1 className="text-3xl font-bold text-center lg:text-left">
                      {profile.first_name} {profile.last_name}
                    </h1>
                    <p className="mb-0 text-lg italic">{profile.location}</p>
                    <p className="text-base font-bold uppercase text-gold">
                      {profile.certification_date &&
                        'Certified since ' + new Date(profile.certification_date).getFullYear()}
                    </p>
                  </div>
                  <div className="col-span-2 my-8">
                    {!!!modalProfileId && (
                      <div>
                        <a
                          name="contact"
                          className="btn btn--primary"
                          role="button"
                          onClick={() => {
                            window.scrollTo({ top: this.contactForm.current.offsetTop, behavior: 'smooth' })
                          }}
                        >
                          Contact Me
                        </a>
                      </div>
                    )}
                    {!!profile.website && (
                      <div className="mt-3">
                        <a
                          className="btn btn--primary-ghost"
                          href={profile.website.indexOf('://') === -1 ? 'http://' + profile.website : profile.website}
                          onClick={this.trackWebsiteClick}
                          target="_blank"
                        >
                          Visit Website
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="col-span-2 mb-8 sm:col-span-1">
                    <h3 className="mb-4 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                      Skills
                    </h3>
                    <ul>
                      {profile.skills.map((skill, i) => (
                        <li key={i}>{skill.name}</li>
                      ))}
                    </ul>
                  </div>
                  {!!profile.industry && (
                    <div className="col-span-2 sm:col-span-1">
                      <h3 className="mb-4 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                        Areas of Focus
                      </h3>
                      <p className="text-md">{profile.industry}</p>
                    </div>
                  )}
                </div>
              </aside>
              <div className="col-span-2 content">
                <section className="mb-8">
                  <div className="flex items-end justify-between pb-2 mb-4">
                    <h3 className="text-lg tracking-widest uppercase">Portfolio</h3>
                    <div>
                      <a className="Arrow Arrow--left" onClick={() => this.slider.current.slickPrev()}>
                        <span>&lsaquo;</span>
                      </a>
                      <a className="Arrow Arrow--right" onClick={() => this.slider.current.slickNext()}>
                        <span>&rsaquo;</span>
                      </a>
                    </div>
                  </div>
                  <div className="mb-8">
                    <Slider arrows={false} ref={this.slider}>
                      {profile.portfolio.map((project, i) => (
                        <div className="item" key={i}>
                          {project.image_url ? (
                            <div className="">
                              <img className="object-cover" alt="Custom Project Image" src={project.image_url} />
                            </div>
                          ) : (
                            <div className="itemPlaceholder">{project.client_name ? project.client_name : null}</div>
                          )}
                          <div className="grid grid-cols-5">
                            {project.client_name || project.external_link ? (
                              <div className="col-span-5 p-8 bg-white sm:col-span-2">
                                {project.client_name ? (
                                  <h5 className="mb-1 tracking-widest uppercase">Client</h5>
                                ) : null}
                                {project.client_name ? (
                                  <h4 className="font-bold text-left">{project.client_name}</h4>
                                ) : null}
                                {project.external_link ? (
                                  <h5 className="mt-6 mb-1 tracking-widest uppercase">Related Media</h5>
                                ) : null}
                                {project.external_link ? (
                                  <a
                                    className="block overflow-hidden overflow-ellipsis text-gold whitespace-nowrap"
                                    href={
                                      project.external_link.indexOf('://') === -1
                                        ? 'http://' + project.external_link
                                        : project.external_link
                                    }
                                    target="_blank"
                                  >
                                    {project.external_link.indexOf('://') === -1
                                      ? 'http://' + project.external_link
                                      : project.external_link}
                                  </a>
                                ) : null}
                              </div>
                            ) : null}
                            <div className="col-span-5 p-8 bg-gray-100 sm:col-span-3 whitespace-pre-line">
                              <p className="mb-0 text-md">{project.project_description}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </section>
                <section className="mb-16">
                  <h3 className="mb-4 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                    About
                  </h3>
                  <p dangerouslySetInnerHTML={{ __html: profile.pitch }} />
                </section>
                <section className="mb-16">
                  <h3 className="mb-4 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                    Certifications
                  </h3>

                  {this.state.loadingGrants && <CertificationsLoader />}
                  <div className="flex lg:flex-col flex-row gap-2">
                    {this.state.profileGrants.map(certification => {
                      if (!GRANT_WHITELIST.includes(certification.grant)) return <Fragment></Fragment>

                      return (
                        <Certification
                          key={certification.id}
                          imgSrc={CERTIFICATION_GRANTS_LOOKUP[certification.grant].imgSrc}
                          certificationName={CERTIFICATION_GRANTS_LOOKUP[certification.grant].certificationName}
                          certificationCopy={CERTIFICATION_GRANTS_LOOKUP[certification.grant].certificationCopy}
                        />
                      )
                    })}
                  </div>
                </section>
                {profile.testimonies && profile.testimonies.length ? (
                  <section className="mb-16">
                    <h3 className="mb-6 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                      Testimonials
                    </h3>
                    <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
                      {profile.testimonies
                        .sort((a, b) => +a.sort - +b.sort)
                        .map((testimony, i) => (
                          <div>
                            <Testimonial key={i} testimony={testimony} />
                          </div>
                        ))}
                    </div>
                  </section>
                ) : null}
                {modalProfileId ? null : (
                  <section className="mb-16" ref={this.contactForm}>
                    <h3 className="mb-4 text-lg tracking-widest uppercase border-b border-gray-200 border-solid">
                      Contact Me
                    </h3>
                    <Contact profile={profile} />
                  </section>
                )}
              </div>
            </div>
          </section>
        </div>
      )
    }

    return renderMMS()
  }
}

export default Profile
