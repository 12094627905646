import React, { Fragment, useState } from 'react'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { Link } from 'react-router-dom'
import { ProfileModal } from '../../profile/components'

export default function Card({ profile, isSelectable, handleSelectedContact, selectedContacts, inProfileModal }) {
  const [open, setOpen] = useState(false)
  const [hovered, setHovered] = useState(false)

  const onOpenModal = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const onCloseModal = () => {
    setOpen(false)
  }

  const isSelected = () => {
    if (!selectedContacts) return

    let selected = Object.keys(selectedContacts).some(key => {
      return selectedContacts[key].id === profile.id
    })
    return selected
  }

  const handleEnter = () => {
    if (isSelected()) {
      setHovered(true)
    }
  }

  const handleLeave = () => {
    setHovered(false)
  }

  let averageCost = ''
  if (profile) {
    switch (profile.price) {
      case 1:
        averageCost = '$500'
        break
      case 2:
        averageCost = '$1,500'
        break
      case 3:
        averageCost = '$5,000'
        break
      case 4:
        averageCost = '$10,000'
        break
      default:
        averageCost = '$10,000'
    }
  }

  const handleAndClose = profile => {
    setOpen(false)

    setTimeout(() => {
      handleSelectedContact(profile)
    }, 600)
  }

  const handleSelect = profile => {
    if (open) {
      return
    }
    handleSelectedContact(profile)
  }

  const Tag = isSelectable ? 'div' : Link

  return (
    <div className={`Card--wrapper ${inProfileModal ? 'in-profile-modal' : ''}`} data-app={'mms'}>
      <div className={`highlight ${isSelected() ? 'highlight--selected' : ''}`}>
        <Tag
          to={isSelectable ? '' : profile.slug}
          onClick={isSelectable ? () => handleSelect(profile) : () => {}}
          className={`Card`}
          onMouseEnter={handleEnter}
          onMouseLeave={handleLeave}
        >
          <div
            className="Card__cover"
            style={{
              backgroundImage:
                'url(' +
                (profile.hero_image_url ? profile.hero_image_url : require('../../../assets/hero-image.jpg')) +
                ')',
            }}
          />
          <div className="Card__avatar" style={{ backgroundImage: 'url(' + profile.image_url + ')' }} />
          <div className="Card-meta">
            <div className="Card-meta__certs">
              {profile.topCertification && profile.topCertification.name.toLowerCase() === 'agency' && (
                <div className="Card__pill agency">
                  Agency
                  <div className="tooltip large">
                    This entire team has been trained, so no matter who you talk to at their agency you'll be in good
                    hands! If you are a larger company looking for a full-suite agency, look no further than one of
                    ours!
                  </div>
                </div>
              )}
              {profile.workshopCertified && profile.workshopCertified.name === 'workshop facilitator' && (
                <div className="Card__pill workshop">
                  Facilitator
                  <div className="tooltip large">
                    This Guide is equipped to put on a StoryBrand Private Workshop for your team. It's perfect if you
                    need to get everyone on the same page before moving forward with a big marketing effort.
                  </div>
                </div>
              )}
            </div>
          </div>
          {profile.distance !== null && !isNaN(profile.distance) && (
            <div className="Card__distance">
              <span className="Card__pin-icon"></span>
              {`${Math.round(profile.distance)} Miles Away`}
            </div>
          )}
          <div className="Card__title">
            {profile.first_name} {profile.last_name}
          </div>
          <div className="Card__location">{profile.location}</div>
          <p className="Card__certified">
            {profile.certification_date && 'Certified since ' + new Date(profile.certification_date).getFullYear()}
          </p>
          <div
            className="Card__copy"
            dangerouslySetInnerHTML={{
              __html: profile.excerpt ? profile.excerpt : profile.pitch,
            }}
          />
          {isSelectable && (
            <Fragment>
              <div className="Card__select-coach">
                <button className="Button Button--gold">
                  {isSelected() ? 'Remove This Coach' : 'Select This Coach'}
                </button>
              </div>
              <div className="Card__see-profile">
                <button onClick={onOpenModal}>See Profile</button>
              </div>
            </Fragment>
          )}
          <div className="Card--overlay">
            <div className={`Card--overlay__inner ${hovered ? 'hovered' : ''}`}></div>
          </div>
          <Modal
            open={open}
            onClose={onCloseModal}
            center
            closeIcon={
              <div className="close-button" data-app={'mms'}>
                <img src={require('../../../assets/Close-Icon.png').default} alt="Close Button" />
              </div>
            }
            classNames={{ modal: 'profileModal' }}
          >
            <ProfileModal
              profile={profile}
              isSelectable={isSelectable}
              isSelected={isSelected()}
              handleAndClose={handleAndClose}
            />
          </Modal>
        </Tag>
      </div>
    </div>
  )
}
