import * as React from 'react'
import { Component } from 'react'
import { Link } from 'react-router-dom'

function MMSHeader({ handleClose }) {
  return (
    <div data-st="st-six" role="menu">
      <nav data-app={'mms'}>
        <ul>
          <li>
            <a
              className="flex items-center gap-4 flex-nowrap"
              href="https://storybrand.com/sales-funnel-plan/"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Sales Funnel Plan
              <figure>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    d="M9 11.1818V1"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17 11.1818V17H1V11.1818"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.6365 7.54546L9.00013 11.1818L5.36377 7.54546"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </figure>
            </a>
          </li>

          <li>
            <a href="https://storybrand.com/guide/" target="_blank" rel="noopener noreferrer">
              Get Certified
            </a>
          </li>
          <li>
            <a href={process.env.REACT_APP_ADMIN_LOCATION + '/login'} target="_blank" rel="noopener noreferrer">
              Login
            </a>
          </li>
          <li>
            <Link className="hiring-gold-border-dark hover-light p-4 rounded-full" to="/search" onClick={handleClose}>
              Search Now
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hamburgered: false,
    }
  }

  handleToggle = () => {
    this.setState({ hamburgered: !this.state.hamburgered })
  }

  handleClose = () => {
    this.setState({ hamburgered: false })
  }

  render() {
    const calculateSearchLink = () => {
      const { router } = this.props
      try {
        if (router.location.pathname.indexOf('podcast') > -1) {
          return '/search/coach-consult'
        }
      } catch {
        //
      }
      return '/search'
    }

    return (
      <header style={{ zIndex: '50' }} className={'cf ' + (this.state.hamburgered ? 'hamburgered' : '')}>
        <div data-st="st-row" className="pw menu-wrapper">
          <div data-st="st-six" className="nav-left">
            <Link to="/">
              <img
                className="md:h-28 md:w-28 h-20 w-20 object-cover"
                src={require('../../../assets/mms-new-logo.png').default}
                alt="Marketing Made Simple"
              />
            </Link>
            <a href="#" id="toggle" onClick={this.handleToggle}>
              <div className="patty" data-app={'mms'}></div>
            </a>
          </div>
          <MMSHeader handleClose={this.handleClose} />
        </div>
      </header>
    )
  }
}

export default Header

//TODO:darken gradient
