import axios from 'axios'
import React, { Fragment, useEffect, useLayoutEffect, useReducer, useRef, useState } from 'react'
import ReactGA from 'react-ga4'
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'
import TagManager from 'react-gtm-module'
import { Helmet } from 'react-helmet'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory, useLocation } from 'react-router'
import { Card } from '../search/components'
import ModalFilters from './ModalFilters'
import { Input, Label } from './UI/Forms'

const searchEventArguments = {
  dataLayer: { event: 'searchEvent' },
  dataLayerName: 'CYMDataLayer',
}

//Top level boolean

//filters both sites share
const SHARED_FILTERS = {
  query: '',
  name: '',
  lat: '',
  lng: '',
  country: '',
  locationDisplayName: '',
  locationData: null,
  locationAttempted: false,
  showFooter: true, //MOVE TO INITIAL STATE
  appName: process.env.REACT_APP_NAME, //MOVE TO INITIAL STATE
  skills: [],
  industries: [],
  attributes: [],
  refreshFilterCount: 0,
}

//filters unique to mms
const MMS_FILTERS = {
  ...SHARED_FILTERS,
  budget: '',
  certifications: [],
  companyAnnualRevenues: [],
}

//initial state
const initialState = {
  filters: MMS_FILTERS,
  status: 'IDLE',
  selectedFilters: {},
  previousFiltersCount: 0,
  selectedFiltersCount: 0,
  openModalOnLoad: false,
  nextPageURL: '',
  //new stuff
  profiles: [],
  hasMoreProfiles: true,
  gotZeroResultsFromSearch: false,
  noMoreProfilesFromSearch: false,
  recommendedProfiles: [],
  gotInitialRecommendedProfiles: false,
  hasMoreRecommendedProfiles: false,
  disabled: false,
  searchCriteriaFeedBack: null,
}

const FETCHING_INITIAL_DATA = 'FETCHING_INITIAL_DATA'
const FETCHING_PROFILES = 'FETCHING_PROFILES'
const FETCHING_RECOMMENDED_PROFILES = 'FETCHING_RECOMMENDED_PROFILES'

const FAILED = 'FAILED'
const GOT_INITIAL_DATA = 'GOT_INITIAL_DATA'
const GOT_RESULTS = 'GOT_RESULTS'
const GOT_MORE_PROFILES = 'GOT_MORE_PROFILES'
const GOT_LOCATION_DATA = 'GOT_LOCATION_DATA'
const GOT_MORE_RECOMMENDED_PROFILES = 'GOT_MORE_RECOMMENDED_PROFILES'

const SEARCHING_FOR_PROFILES = 'SEARCHING_FOR_PROFILES'
const REMOVE_SELECTED_FILTER = 'REMOVE_SELECTED_FILTER'
const INCREMENT_SELECTED_FILTERS_COUNT = 'INCREMENT_SELECTED_FILTERS_COUNT'
const DECREMENT_SELECTED_FILTERS_COUNT = 'DECREMENT_SELECTED_FILTERS_COUNT'

const IS_MAKING_ADJUSTMENTS = 'IS_MAKING_ADJUSTMENTS'
const ADJUSTMENTS_COMPLETE = 'ADJUSTMENTS_COMPLETE'

const RESET_KEYWORD = 'RESET_KEYWORD'
const RESET_LOCATION_DATA = 'RESET_LOCATION_DATA'
const RESET_MODAL_FILTERS = 'RESET_MODAL_FILTERS'

const OPEN_FILTERS_MODAL = 'OPEN_FILTERS_MODAL'
const NAME_FOCUS_IGNORED = 'NAME_FOCUS_IGNORED'
const FILTERS_MODAL_CLOSED = 'FILTERS_MODAL_CLOSED'

const GOT_PROFILES = 'GOT_PROFILES'
const NO_MORE_PROFILES = 'NO_MORE_PROFILES'
const GOT_ZERO_RESULTS_FROM_SEARCH = 'GOT_ZERO_RESULTS_FROM_SEARCH'
const GOT_RECOMMENDED_PROFILES = 'GOT_RECOMMENDED_PROFILES'
const SEARCHING = 'SEARCHING'
const GOT_API_FILTERS = 'GOT_API_FILTERS'

export const UPDATE_BUDGET = 'UPDATE_BUDGET'
export const ADD_TO_SELECTED_FILTER = 'ADD_TO_SELECTED_FILTER'
export const ADD_TO_SELECTED_FILTER_WITH_IDS = 'ADD_TO_SELECTED_FILTER_WITH_IDS'
export const CLEAR_SELECTED_FILTER = 'CLEAR_SELECTED_FILTER'
export const ADD_TO_SELECTED_FILTER_IDS = 'ADD_TO_SELECTED_FILTER_IDS'
export const REMOVE_SELECTED_FILTER_IDS = 'REMOVE_SELECTED_FILTER_IDS'

const SET_PRE_SELECTED_FILTERS = 'SET_PRE_SELECTED_FILTERS'

const NARROW_CRITERIA = 'NARROW_CRITERIA'
const WIDEN_CRITERIA = 'WIDEN_CRITERIA'

function reducer(state, action) {
  const { type, payload } = action
  switch (type) {
    case GOT_API_FILTERS: {
      return {
        ...state,
        filters: { ...state.filters, ...payload.filters },
      }
    }
    case WIDEN_CRITERIA:
      return {
        ...state,
        searchCriteriaFeedBack: {
          type: 'widening',
          totalResults: payload,
        },
      }
    case NARROW_CRITERIA:
      return {
        ...state,
        searchCriteriaFeedBack: {
          type: 'narrowing',
          totalResults: payload,
        },
      }

    case SET_PRE_SELECTED_FILTERS:
      return {
        ...state,
        previousFiltersCount: 0,
        selectedFiltersCount: 1,
        selectedFilters: {
          [payload.filterKey]: [payload.filterID],
        },
      }

    case SEARCHING:
      return {
        ...state,
        status: SEARCHING,
        //reset
        hasMoreProfiles: true,
        hasMoreRecommendedProfiles: false,
        noMoreProfilesFromSearch: false,
        gotInitialRecommendedProfiles: false,
        profiles: [],
        recommendedProfiles: [],
        gotZeroResultsFromSearch: false,
        disabled: true,
        searchCriteriaFeedBack: null,
      }

    case GOT_ZERO_RESULTS_FROM_SEARCH:
      return {
        ...state,
        firstPageURL: payload.firstPageURL,
        hasMoreProfiles: false,
        gotZeroResultsFromSearch: true,
        disabled: false,
        status: GOT_ZERO_RESULTS_FROM_SEARCH,
        profiles: [],
        searchCriteriaFeedBack: null,
      }

    case FETCHING_PROFILES:
      return {
        ...state,
        disabled: true,
      }

    //initial fetch or search - resolved
    case GOT_PROFILES:
      return {
        ...state,
        status: GOT_PROFILES,
        profiles: payload.profiles,
        hasMoreProfiles: payload.hasMoreProfiles,
        firstPageURL: payload.firstPageURL,
        nextPageURL: payload.nextPageURL,
        //reset
        recommendedProfiles: [],
        hasMoreRecommendedProfiles: false,
        noMoreProfilesFromSearch: true,
        gotInitialRecommendedProfiles: false,
        disabled: false,
      }

    //scrolling down page
    case GOT_MORE_PROFILES:
      return {
        ...state,
        disabled: false,
        profiles: state.profiles.concat(payload.profiles),
        hasMoreProfiles: payload.hasMoreProfiles,
        firstPageURL: payload.firstPageURL,
        nextPageURL: payload.nextPageURL,

        status: GOT_MORE_PROFILES,
      }
    case NO_MORE_PROFILES:
      return {
        ...state,
        disabled: false,
        hasMoreProfiles: false,
        status: GOT_MORE_PROFILES,
      }

    case FETCHING_RECOMMENDED_PROFILES:
      return {
        ...state,
        disabled: true,
      }
    //initial fetch or search
    case GOT_RECOMMENDED_PROFILES:
      return {
        ...state,
        recommendedProfiles: payload.profiles,
        hasMoreRecommendedProfiles: payload.hasMoreProfiles,
        nextPageURL: payload.nextPageURL,
        gotInitialRecommendedProfiles: true,
        status: GOT_RECOMMENDED_PROFILES,
        disabled: false,
      }

    //scrolling down page
    case GOT_MORE_RECOMMENDED_PROFILES:
      return {
        ...state,
        recommendedProfiles: state.recommendedProfiles.concat(payload.recommendedProfiles),
        hasMoreRecommendedProfiles: payload.hasMoreProfiles,
        disabled: false,
        nextPageURL: payload.nextPageURL,
        status: GOT_MORE_RECOMMENDED_PROFILES,
      }

    case FAILED:
      return {
        ...state,
        failed: true,
      }

    case UPDATE_BUDGET:
      return {
        ...state,
        selectedFilters: payload.selectedFilters,
        selectedFiltersCount: payload.selectedFiltersCount,
        previousFiltersCount: state.selectedFiltersCount,
      }

    case IS_MAKING_ADJUSTMENTS:
      return {
        ...state,
        disabled: true,
      }

    case ADJUSTMENTS_COMPLETE:
      return {
        ...state,
        disabled: false,
      }

    case INCREMENT_SELECTED_FILTERS_COUNT:
      return {
        ...state,
        selectedFiltersCount: state.selectedFiltersCount + 1,
        previousFiltersCount: state.selectedFiltersCount,
      }

    case DECREMENT_SELECTED_FILTERS_COUNT:
      return {
        ...state,
        selectedFiltersCount: state.selectedFiltersCount - 1,
        previousFiltersCount: state.selectedFiltersCount,
      }

    //non - array filter
    case ADD_TO_SELECTED_FILTER:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          //update the appropriate filter based on a key
          [payload.filterKey]: payload.filterValue,
        },
      }

    case CLEAR_SELECTED_FILTER:
      return {
        ...state,
        selectedFilters: payload,
      }

    case ADD_TO_SELECTED_FILTER_IDS:
      return {
        ...state,
        previousFiltersCount: state.selectedFiltersCount,
        selectedFiltersCount: state.selectedFiltersCount + 1,
        selectedFilters: {
          ...state.selectedFilters,
          //update the appropriate filter based on a key - add to the array of filter ids
          [payload.filterKey]: state.selectedFilters[payload.filterKey].concat(payload.filterID),
        },
      }

    //brand new selected filter that has ids
    case ADD_TO_SELECTED_FILTER_WITH_IDS:
      return {
        ...state,
        previousFiltersCount: state.selectedFiltersCount,
        selectedFiltersCount: state.selectedFiltersCount + 1,
        selectedFilters: {
          ...state.selectedFilters,
          [payload.filterKey]: [payload.id],
        },
      }

    case REMOVE_SELECTED_FILTER_IDS:
      return {
        ...state,
        selectedFilters: payload.selectedFilters,
        selectedFiltersCount: state.selectedFiltersCount - 1,
        previousFiltersCount: state.selectedFiltersCount,
      }

    case REMOVE_SELECTED_FILTER:
      return {
        ...state,
        selectedFilters: payload.selectedFilters,
      }

    case GOT_INITIAL_DATA:
      return {
        ...state,
        filters: { ...state.filters, ...payload.filters },
        profiles: payload.profiles,
        status: GOT_INITIAL_DATA,
        firstPageURL: payload.firstPageURL,
        nextPageURL: payload.nextPageURL,
        disabled: false,
      }

    case FETCHING_PROFILES:
      return {
        ...state,
        status: FETCHING_PROFILES,
      }

    case GOT_LOCATION_DATA:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          lat: payload.lat,
          lng: payload.lng,
          // locationDisplayName: payload.locationDisplayName,
          // locationData: payload.locationData,
        },
        status: GOT_LOCATION_DATA,
        disabled: false,
      }

    case RESET_LOCATION_DATA:
      return {
        ...state,
        disabled: false,
        selectedFilters: payload.selectedFilters,

        status: RESET_LOCATION_DATA,
      }

    case RESET_KEYWORD:
      return {
        ...state,
        disabled: false,
        selectedFilters: payload.selectedFilters,
      }

    case RESET_MODAL_FILTERS:
      return {
        ...state,
        selectedFilters: payload.selectedFilters,
        previousFiltersCount: state.selectedFiltersCount,
        selectedFiltersCount: payload.selectedFiltersCount,
        disabled: false,
        refreshFilterCount: state.refreshFilterCount + 1,
      }

    case OPEN_FILTERS_MODAL:
      return {
        ...state,
        openModalOnLoad: true,
        disabled: true,
        filters: payload.filters,
        status: OPEN_FILTERS_MODAL,
      }

    case FILTERS_MODAL_CLOSED:
      return {
        ...state,
        openModalOnLoad: false,
        disabled: false,
      }
    case NAME_FOCUS_IGNORED:
      return {
        ...state,
        openModalOnLoad: false,
        disabled: false,
        status: FETCHING_INITIAL_DATA,
      }

    case GOT_RESULTS:
      return {
        ...state,
        results: payload.results,
        firstPageURL: payload.firstPageURL,
        nextPageURL: payload.nextPageURL,
        lastPage: payload.lastPage,
        status: GOT_RESULTS,
        disabled: false,
        hitBottom: payload.hitBottom ?? false,
      }

    case GOT_MORE_PROFILES:
      return {
        ...state,
        results: state.results.concat(payload.results),
        nextPageURL: payload.nextPageURL,
        status: GOT_MORE_PROFILES,
        disabled: false,
      }

    default:
      return state
  }
}
function LoadingSpinner() {
  return (
    <div style={{ backgroundColor: '#f1f1f4' }} className="pb-10">
      <div className="loading pw">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
}
function LineSeparator({ isHovering }) {
  return (
    <div
      className={`lg:block hidden h-12 transition-all duration-200 `}
      style={{ width: '1px', backgroundColor: isHovering ? 'transparent' : '#cecece' }}
    ></div>
  )
}

function Filter({
  selected,
  handleSelect,
  disabled = false,
  onMouseEnter,
  onMouseLeave,
  onClear,
  onClickOutside,
  modalOpen = false,
  children,
}) {
  const ref = useRef()

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && event.target.id !== 'clear' && !ref.current.contains(event.target) && selected && !modalOpen) {
        onClickOutside()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, selected, onClickOutside])

  return (
    <div
      ref={ref}
      onClick={() => {
        if (disabled || selected) {
          return {}
        }

        handleSelect()
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ height: '100px' }}
      className={`relative w-full flex flex-grow justify-between items-center transition-all duration-200 lg:rounded-full ${
        selected ? 'bg-white' : 'bg-transparent hover:bg-gray-200'
      } p-4 rounded-xl`}
    >
      {children}

      {selected ? (
        <button
          style={{ zIndex: 999 }}
          type="button"
          onClick={onClear}
          className="z-50 border-0 rounded-full flex items-center justify-center p-1 transition-all duration-200 cursor-pointer active:bg-white relative"
        >
          <svg
            id="clear"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      ) : null}
    </div>
  )
}

function Flex({ children, className }) {
  return <div className={`flex w-full ${className}`}>{children}</div>
}

function HandlePlural({ total }) {
  return <>{total === 1 ? 'Guides' : 'Guides'}</>
}

function SearchFeedBack({ type, total }) {
  return (
    <div className="rounded-full bg-white text-center py-6 w-11/12 mx-auto">
      <p className="text-sm">
        Your search found{' '}
        <span className="font-bold text-gold">
          {total} <HandlePlural total={total} />
        </span>
        . Try {type} your search by using {type === 'narrowing' ? 'more' : 'less'} specific keywords and filters.
      </p>
    </div>
  )
}

function Search({ state, dispatch, handleSearch, handleStringFilter, handleGoogleLocation }) {
  const location = useLocation()
  const [hoveredOption, setHoveredOption] = useState(-1)
  const [selectedOption, setSelectedOption] = useState(location.state?.focusName ? 2 : 0)
  const [googleLocationState, setGoogleLocationState] = useState(null)

  const formRef = useRef()
  const googleLocationInputRef = useRef()

  const isDisabled = state.disabled

  useEffect(() => {
    if (state.openModalOnLoad) {
      setSelectedOption(2)
    }
  }, [state.openModalOnLoad])

  useEffect(() => {
    if (state.status === SEARCHING_FOR_PROFILES) {
      setSelectedOption(-1)
    }
  }, [state.status])

  function toggleSelectedOption(id) {
    setSelectedOption(id)
  }

  function resetSelectedOption() {
    setSelectedOption(-1)
  }

  function resetKeywords() {
    setSelectedOption(-1)
    //remove query from selected filters
    const { query, ...rest } = state.selectedFilters
    //update state
    dispatch({ type: RESET_KEYWORD, payload: { selectedFilters: rest } })
  }

  function resetLocation() {
    //remove location data from selected filters
    const { lat, lng, ...rest } = state.selectedFilters

    //update parent state
    dispatch({ type: RESET_LOCATION_DATA, payload: { selectedFilters: rest } })
    //update local state
    setGoogleLocationState(null)

    //resetSelectedOption()
  }

  function resetFilters() {
    //resetSelectedOption()

    //remove nested filters that are being set from modal
    const keysToKeep = {
      query: 0,
      lat: 0,
      lng: 0,
      locationData: 0,
      locationDisplayName: 0,
      locationAttempted: 0,
    }

    let count = state.selectedFiltersCount
    //object to update state
    const clean = {}

    for (const key in state.selectedFilters) {
      //if the key is one we want to keep add the key and value to the object
      if (key in keysToKeep) {
        clean[key] = state.selectedFilters[key]
      } else {
        if (Array.isArray(state.selectedFilters[key])) {
          count -= state.selectedFilters[key].length
        } else {
          count -= 1
        }
      }
    }

    //update state
    dispatch({ type: RESET_MODAL_FILTERS, payload: { selectedFilters: clean, selectedFiltersCount: count } })
  }

  function handleFocus(e) {
    dispatch({ type: IS_MAKING_ADJUSTMENTS })
  }

  function onClickOutside() {
    setSelectedOption(-1)
    dispatch({ type: ADJUSTMENTS_COMPLETE })
  }

  function triggerFormSubmit(e) {
    if (e.key === 'Enter') {
      formRef.current.requestSubmit()
    }
  }

  return (
    <form
      ref={formRef}
      onSubmit={handleSearch}
      className="w-11/12 mx-auto border border-solid border-gray-200 lg:rounded-full rounded-xl p-0 flex lg:flex-row lg:items-center flex-col my-10 justify-center relative"
    >
      <Filter
        selected={selectedOption === 0}
        // disabled={isDisabled}
        handleSelect={() => {
          dispatch({ type: IS_MAKING_ADJUSTMENTS })
          toggleSelectedOption(0)
        }}
        onMouseEnter={() => setHoveredOption(0)}
        onMouseLeave={() => setHoveredOption(-1)}
        onClear={resetKeywords}
        onClickOutside={onClickOutside}
      >
        <Flex className="flex-col items-start w-full space-y-4 pl-8">
          <Label htmlFor="keyword search">Keyword Search</Label>
          <Input
            key={state.selectedFilters.query}
            placeholder="E.g. 'Web Design' or 'Copywriting'"
            type="text"
            id="query"
            onFocus={handleFocus}
            shouldFocus={selectedOption === 0}
            // disabled={selectedOption !== 0}
            onChange={handleStringFilter}
            value={state.selectedFilters.query}
          />
        </Flex>
      </Filter>

      <LineSeparator
        isHovering={hoveredOption === 0 || hoveredOption === 1 || selectedOption === 0 || selectedOption === 1}
      />
      <Filter
        selected={selectedOption === 1}
        // disabled={isDisabled}
        handleSelect={() => {
          // if (selectedOption === 1) {
          //   return {}
          // }
          googleLocationInputRef.current.focus()

          setSelectedOption(1)
        }}
        onMouseEnter={() => setHoveredOption(1)}
        onMouseLeave={() => setHoveredOption(-1)}
        onClear={resetLocation}
        onClickOutside={onClickOutside}
      >
        <Flex className="flex-col items-start w-full space-y-4 pl-8 ">
          <Label htmlFor="location">Location</Label>
          <div
            style={{ zIndex: 999 }}
            className={`w-10/12  
            
            ${isDisabled && selectedOption !== 1 ? 'pointer-events-none' : ''}
            
            `}
          >
            <GooglePlacesAutocomplete
              apiKey="AIzaSyAvOjlvCXr-iTHlKmutLq-CA0GaTIviI_Y"
              selectProps={{
                isClearable: false,
                value: googleLocationState,
                ref: googleLocationInputRef,

                onFocus: e => {
                  e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)

                  //handleFocus()
                },
                onChange: e => {
                  //local state
                  setGoogleLocationState(e)
                  //parent state
                  handleGoogleLocation(e)
                },
                // clearValue: this.handleClear,
                placeholder: `Search City, State/Province`,
                styles: {
                  control: (provided, state) => ({
                    ...provided,
                    background: 'transparent',
                    fontFamily: 'Montserrat',
                    fontSize: '0.9rem',
                    fontWeight: '400',
                    color: 'black',
                    boxShadow: 'none !important',
                    border: '0px !important',
                    // border: false ? '3px solid #2d3354 !important' : '2px solid #2d3354 !important',
                    cursor: 'text',

                    width: '100%',
                    marginTop: '-6px',
                    marginBottom: '0px',
                    padding: '0px',
                  }),
                  placeholder: provided => ({
                    ...provided,
                    fontFamily: 'Montserrat',
                    color: '#050505',
                    fontSize: '0.9rem',
                    fontWeight: '400',
                    padding: '0px',
                    margin: '0px',
                    textAlign: 'left',
                  }),
                  input: provided => ({
                    ...provided,
                    color: 'black',
                    fontFamily: 'Montserrat',
                    fontSize: '0.9rem',
                    fontWeight: '400',
                    textAlign: 'left',
                    padding: '0px',
                  }),
                  valueContainer: provided => ({
                    ...provided,
                    color: 'black',
                    textAlign: 'left',
                    padding: '0px',
                  }),
                  menu: provided => ({
                    ...provided,
                    color: 'black',

                    border: 'none',
                    boxShadow: '0px 1px 5px #c3d0d8',
                  }),
                  menuList: provided => ({
                    ...provided,
                    color: 'black',
                    padding: '0px',

                    width: '100%',
                  }),
                  option: provided => ({
                    ...provided,
                    color: 'black',
                  }),
                  noOptionsMessage: provided => ({
                    ...provided,
                    display: 'none',
                  }),
                  clearIndicator: provided => ({
                    ...provided,
                    border: '2x solid black',
                  }),
                  indicatorSeparator: provided => ({
                    ...provided,
                    display: 'none',
                  }),
                  dropdownIndicator: provided => ({
                    ...provided,
                    display: 'none',
                  }),
                  indicatorsContainer: provided => ({
                    ...provided,
                  }),
                  singleValue: provided => ({
                    ...provided,
                    color: 'black',
                    padding: '0px',
                    textAlign: 'left',
                  }),
                },
              }}
            />
          </div>
        </Flex>
      </Filter>
      <LineSeparator
        isHovering={hoveredOption === 1 || hoveredOption === 2 || selectedOption === 1 || selectedOption === 2}
      />

      <Filter
        selected={selectedOption === 2}
        handleSelect={() => {
          dispatch({ type: IS_MAKING_ADJUSTMENTS })
          toggleSelectedOption(2)
        }}
        disabled={isDisabled}
        onMouseEnter={() => setHoveredOption(2)}
        onMouseLeave={() => setHoveredOption(-1)}
        onClear={resetFilters}
        onClickOutside={onClickOutside}
        modalOpen={selectedOption === 2}
      >
        <Flex className="flex-col items-start w-full space-y-4 pl-8">
          <Label>Filters</Label>
          <input
            readonly
            className="Input p-0 m-0 bg-transparent focus:outline-none pointer-events-none"
            placeholder={
              state.selectedFiltersCount === 0
                ? 'Add Filters'
                : `${state.selectedFiltersCount} filter${state.selectedFiltersCount > 1 ? 's' : ''} active`
            }
          />
        </Flex>
      </Filter>

      <ModalFilters
        open={selectedOption === 2}
        handleClose={() => {
          setSelectedOption(-1)
          //only ignore if there are no nested filters selected in selected filters
          if (state.openModalOnLoad && !Object.keys(state.selectedFilters).length) {
            dispatch({ type: NAME_FOCUS_IGNORED })
          } else {
            dispatch({ type: FILTERS_MODAL_CLOSED })
          }

          if (state.selectedFiltersCount !== 0) {
            //trigger search on close
            handleSearch()
          }

          if (state.selectedFiltersCount === 0 && state.previousFiltersCount !== 0) {
            //user reset filters applied through modal
            handleSearch()
          }
        }}
        state={state}
        dispatch={dispatch}
        handleStringFilter={handleStringFilter}
        triggerFormSubmit={triggerFormSubmit}
      />

      <div class="Form--submit lg:mt-0 mt-4 lg:mr-4">
        <button
          class="Button Button--gold lg:h-auto lg:w-12 w-full lg:block flex justify-center items-center space-x-2 -mr -mr"
          type="submit"
          disabled={isDisabled}
        >
          <i class="Icon Icon--search lg:-ml-3"></i>
          <span className="lg:hidden visible">Search for Guides</span>
        </button>
      </div>
    </form>
  )
}

export default function SearchRefactor() {
  const [state, dispatch] = useReducer(reducer, initialState)
  const location = useLocation()
  const history = useHistory()

  useLayoutEffect(() => {
    if (process.env.REACT_APP_GA_ID) {
      //work around for staging
      ReactGA.initialize(process.env.REACT_APP_GA_ID, { debug: true })
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: document.title })
    }

    const listen = history.listen((location, action) => {
      //For HubSpot data
      const _hsq = (window._hsq = window._hsq || [])
      // HubSpot Tracking on each history change
      _hsq.push(['setPath', location.pathname])
      _hsq.push(['trackPageView'])
    })
    return () => {
      listen()

      window.history.replaceState({}, document.title)
    }
  }, [])

  useEffect(() => {
    async function fetchFilters() {
      try {
        //fetch filters
        const { data: apiFilters } = await axios.get(
          `${process.env.REACT_APP_API_LOCATION}/api/coach_filters_new?site=mms`
        )
        dispatch({
          type: GOT_API_FILTERS,
          payload: {
            filters: apiFilters,
          },
        })
        //check if the user is initially searching by a name
        if (location.state?.focusName) {
          //open the filters modal
          dispatch({
            type: OPEN_FILTERS_MODAL,
            payload: {
              filters: apiFilters,
            },
          })
        } else {
          const seed = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)
          let url = `${process.env.REACT_APP_API_LOCATION}/api/profiles?seed=${seed}&appName=mms`
          //fetch initial profiles
          if (location.state?.preSelectedFilters) {
            const item = apiFilters[location.state.preSelectedFilters.type].find(
              f => f.name.toLowerCase() === location.state.preSelectedFilters.name.toLowerCase()
            )
            if (item) {
              dispatch({
                type: SET_PRE_SELECTED_FILTERS,
                payload: {
                  filterKey: location.state.preSelectedFilters.type,
                  filterID: item.id,
                },
              })
            }

            //build the query string
            let queryString
            if (item) {
              queryString = getQueryString({
                [location.state.preSelectedFilters.type]: item.id,
              })
            } else {
              queryString = getQueryString()
            }

            //update url
            url = `${process.env.REACT_APP_API_LOCATION}/api/profiles${queryString}&seed=${seed}&appName=mms`
          }

          const { data: profilesData } = await axios.get(url)

          dispatch({
            type: GOT_INITIAL_DATA,
            payload: {
              profiles: profilesData.data,
              // filters: apiFilters,
              nextPageURL: profilesData.next_page_url,
              firstPageURL: profilesData.first_page_url,
              lastPage: profilesData.last_page,
            },
          })
        }
      } catch (error) {
        console.log(error)
        dispatch({ type: FAILED })
      }
    }

    if (state.status === 'IDLE') {
      fetchFilters()
    }
  }, [state.status])

  useEffect(() => {
    if (!state.hasMoreProfiles && !state.gotInitialRecommendedProfiles) {
      async function fetchRecommendedProfiles() {
        //fetch recommended profiles
        const { data: profilesData } = await axios.get(`${state.firstPageURL}&inverse=true`)

        dispatch({
          type: GOT_RECOMMENDED_PROFILES,
          payload: {
            profiles: profilesData.data,
            nextPageURL: profilesData.next_page_url,
            hasMoreProfiles: profilesData.next_page_url !== null,
          },
        })
      }

      fetchRecommendedProfiles()
    }
  }, [state.hasMoreProfiles, state.gotInitialRecommendedProfiles])

  //build the query string and search
  function getQueryString(stateOverRides = {}) {
    const queryString = ['?']

    const obj = { ...state.selectedFilters, ...stateOverRides }

    const keys = Object.keys(obj)
    const length = keys.length

    for (let i = 0; i < length; i++) {
      //tack on '&' if there are multiple filters
      if (i !== 0) {
        queryString.push('&')
      }
      //current key
      const currentKey = keys[i]

      //current filter
      const filter = obj[currentKey]
      //handle string
      if (typeof filter === 'string' || typeof filter === 'number') {
        queryString.push(`${currentKey}=${encodeURIComponent(filter)}`)
      } else {
        //push on the filter name and it's corresponding ids. ie skills=12,23,12
        queryString.push(`${currentKey}=${filter.join(',')}`)
      }
    }
    //return formatted query string
    return queryString.join('')
  }

  function handleStringFilter(e, isModalFilterInput = false) {
    const { id, value } = e.target

    //user is clearing out the string
    if (value.length === 0) {
      //copy current selectedFilters
      const copy = state.selectedFilters
      //delete key on the copy
      delete copy[id]
      //send updated selectedFilters
      dispatch({ type: CLEAR_SELECTED_FILTER, payload: copy })
    } else {
      dispatch({ type: ADD_TO_SELECTED_FILTER, payload: { filterKey: id, filterValue: value } })
    }

    if (isModalFilterInput) {
      if (value.length !== 0 && !(id in state.selectedFilters)) {
        dispatch({ type: INCREMENT_SELECTED_FILTERS_COUNT })
      }

      //update count
      if (value.length === 0 && state.selectedFiltersCount !== 0) {
        dispatch({ type: DECREMENT_SELECTED_FILTERS_COUNT })
      }
    }
  }

  async function handleGoogleLocation(locationData) {
    try {
      //get geocode
      const [geoCode] = await geocodeByAddress(locationData.label)
      //get lat lng
      const { lat, lng } = await getLatLng(geoCode)
      //update state
      dispatch({
        type: GOT_LOCATION_DATA,
        payload: {
          lat,
          lng,
          locationData,
          locationDisplayName: locationData.label,
        },
      })
    } catch (error) {
      dispatch({ type: FAILED })
    }
  }

  async function handleSearch(e) {
    //prevent default form behavior
    if (e) {
      e.preventDefault()
    }

    //do the javascript equivalent of php's random_int(0, PHP_INT_MAX)
    const seed = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)
    try {
      //loading state
      dispatch({ type: SEARCHING })
      //build the query string
      const queryString = getQueryString()
      //make request
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_LOCATION}/api/profiles${queryString}&seed=${seed}&appName=mms`
      )

      if (data.total === 0) {
        //go ahead and pull in recommended
        dispatch({
          type: GOT_ZERO_RESULTS_FROM_SEARCH,
          payload: {
            firstPageURL: data.first_page_url,
          },
        })
      } else {
        //handle user feedback
        if (data.total > 50) {
          //show alert that criteria should be narrowed
          dispatch({
            type: NARROW_CRITERIA,
            payload: data.total,
          })
        } else {
          //show alert that criteria should be widened
          dispatch({
            type: WIDEN_CRITERIA,
            payload: data.total,
          })
        }

        dispatch({
          type: GOT_PROFILES,
          payload: {
            profiles: data.data,
            firstPageURL: data.first_page_url,
            nextPageURL: data.next_page_url,
            hasMoreProfiles: data.next_page_url !== null,
          },
        })
      }
    } catch (error) {
      dispatch({ type: FAILED })
    }
  }

  async function fetchMoreProfiles() {
    try {
      if (!state.nextPageURL) {
        dispatch({
          type: NO_MORE_PROFILES,
        })

        return
      }

      dispatch({ type: FETCHING_PROFILES })

      const { data } = await axios.get(`${state.nextPageURL}`)

      TagManager.dataLayer(searchEventArguments)

      dispatch({
        type: GOT_MORE_PROFILES,
        payload: {
          profiles: data.data,
          firstPageURL: data.first_page_url,
          nextPageURL: data.next_page_url,
          hasMoreProfiles: data.next_page_url !== null,
        },
      })
    } catch (error) {
      dispatch({ type: FAILED })
    }
  }

  async function fetchMoreRecommendedProfiles() {
    try {
      dispatch({ type: FETCHING_RECOMMENDED_PROFILES })
      //fetch more recommended profiles
      const { data: profilesData } = await axios.get(`${state.nextPageURL}`)

      dispatch({
        type: GOT_MORE_RECOMMENDED_PROFILES,
        payload: {
          recommendedProfiles: profilesData.data,
          nextPageURL: profilesData.next_page_url,
          hasMoreProfiles: profilesData.next_page_url !== null,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }
  //trigger deploy
  if (state.failed) {
    return <p>An unknown error has occurred.</p>
  }

  return (
    <div style={{ backgroundColor: '##faf9fc' }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{'Marketing Made Simple'} | Provider Search Results</title>
      </Helmet>

      <Search
        state={state}
        dispatch={dispatch}
        handleSearch={handleSearch}
        handleStringFilter={handleStringFilter}
        handleGoogleLocation={handleGoogleLocation}
      />

      {state.searchCriteriaFeedBack !== null ? (
        <SearchFeedBack type={state.searchCriteriaFeedBack?.type} total={state.searchCriteriaFeedBack?.totalResults} />
      ) : null}

      {state.gotZeroResultsFromSearch ? (
        <div className="zero-count">
          <div className="zero-count__title">
            <span className="pop">0 experts</span> match your criteria
          </div>
          <div className="zero-count__copy">Use the toolbar above to refine your search</div>
        </div>
      ) : null}

      {state.status === 'IDLE' || state.status === SEARCHING ? <LoadingSpinner /> : null}

      <div className="w-11/12 mx-auto">
        <InfiniteScroll
          dataLength={state.profiles.length}
          next={fetchMoreProfiles}
          hasMore={state.status !== 'IDLE' && state.status !== SEARCHING && state.hasMoreProfiles}
          endMessage={
            <Fragment>
              {state.status !== 'IDLE' || state.status !== SEARCHING || state.gotZeroResultsFromSearch ? null : (
                <div className="Search__no-more">{`There are no more experts matching your search`}</div>
              )}
            </Fragment>
          }
          loader={<LoadingSpinner />}
        >
          <div
            disabled
            className={`Cards transition-all duration-200 ${
              state.isMakingAdjustments ? 'opacity-50 pointer-events-none' : 'opacity-100'
            }`}
            style={{ backgroundColor: '##faf9fc' }}
          >
            {state.profiles.map(result => (
              <Card key={result.id} profile={result} />
            ))}
          </div>
        </InfiniteScroll>

        <div style={{ backgroundColor: '#f1f1f4' }}>
          {state.hasMoreProfiles === false ? (
            <div className="Search__separator">
              <span>Other Recommended Experts</span>
            </div>
          ) : null}

          {!state.hasMoreProfiles && !state.gotInitialRecommendedProfiles ? <LoadingSpinner /> : null}

          {!state.hasMoreProfiles ? (
            <InfiniteScroll
              dataLength={state.recommendedProfiles.length} //This is important field to render the next data
              next={fetchMoreRecommendedProfiles}
              hasMore={state.hasMoreRecommendedProfiles}
              loader={<LoadingSpinner />}
              endMessage={
                <Fragment>
                  {state.gotInitialRecommendedProfiles ? (
                    <div className="Search__no-more">There are no more recommended experts</div>
                  ) : null}
                </Fragment>
              }
            >
              <div className="Cards Cards--more">
                {state.recommendedProfiles.map(result => (
                  <Card key={result.id} profile={result} />
                ))}
              </div>
            </InfiniteScroll>
          ) : null}
        </div>
      </div>
    </div>
  )
}
